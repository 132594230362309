import { colors } from "@mui/material";

export const theme = {
  fonts: {
    primary: "'CustomFont1', sans-serif",
    secondary: "'CustomFont2', serif",
  },
  colors: {
    inputs: "#D5D5D5",
    inputFocus: "#9e9e9e",
  },
};
