import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ArrowLeft, Plus } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../api/axios";
import { USER_EMPLOYEE_PROJECT_ALLOCATION_SCHEMA } from "../../../../utils/schema";
import { FormInputDropdown } from "../../../form-components/FormInputDropdown";
import GenerateDataTable from "../../../reusable/GenerateDataTable";
import StatusDropDown from "../../../reusable/StatusDropDown";
import HeaderSummaryItem from "../../../reusable/HeaderSummaryItem";

export function EmployeeProjectAllocation() {
  const { user_create_id } = useParams();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isViewMode, setIsViewMode] = useState(true);
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [allocations, setAllocations] = useState([]);

  const navigate = useNavigate();

  const loadInitialData = async () => {
    try {
      setLoading(true);
      setSelectedRow(null);
      const [userCreateResponse, projectAllocationResponse] = await Promise.all(
        [
          axiosInstance.get(`/getUsersCreateDetailsId/${user_create_id}`),
          axiosInstance.get(`/indexProjectAllocationClient/${user_create_id}`),
        ]
      );
      if (userCreateResponse?.data?.data) {
        setRows(userCreateResponse?.data?.data);
      }

      if (projectAllocationResponse?.data?.data?.length > 0) {
        const indexedArray = projectAllocationResponse.data.data.map(
          (item, index) => ({
            index,
            ...item,
          })
        );
        setAllocations(indexedArray);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const loadActiveProjects = () => {
    setLoading(true);
    setSelectedRow(null);
    axiosInstance
      .get(`/getActiveAllProjects`)
      .then((response) => {
        if (response.data.error === false) {
          setDropdownData((prev) => ({
            ...prev,
            activeProjects: response.data?.data,
          }));
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadActiveRoles = () => {
    setLoading(true);
    setSelectedRow(null);
    axiosInstance
      .get(`/getActiveAllroles`)
      .then((response) => {
        if (response.data.error === false) {
          setDropdownData((prev) => ({
            ...prev,
            activeRoles: response.data?.data,
          }));
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadInitialData();
    loadActiveProjects();
    loadActiveRoles();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsViewMode(false);
    setUserDetails(null);
  };

  const handleNewRecord = () => {
    setSelectedRow(null);
    setUserDetails(null);
    setIsViewMode(false);
    setOpen(true);
  };

  const handleChangeStatus = async (event, currentRow) => {
    const newStatus = event.target.value;
    if (newStatus == "EDIT") {
      setSelectedRow(currentRow);
      setIsViewMode(false);

      handleOpen();
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/projectAllocationSwitch/${currentRow?.project_allocation_id}`,
        {
          status: newStatus,
        }
      );

      if (response.data.error) {
        console.error(response.data.message);
        toast.error(response.data.message);
      } else {
        loadInitialData();
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error updating account status:", error);
    }
  };
  const viewHandler = (rowData) => {
    setSelectedRow(rowData);
    setIsViewMode(true);
    setOpen(true);
  };

  const columns = [
    {
      field: "index",
      headerName: "S.No",
      flex: 0.25,
      valueGetter: (value) => value + 1,
    },
    {
      field: "project_id",
      headerName: "Project Name",
      flex: 1,
      valueGetter: (value, row) => {
        return `${row?.project_list?.[0]?.project_name}`;
      },
    },
    {
      field: "role_name",
      headerName: "Role Name",
      valueGetter: (value, row) => {
        return `${row?.roles_list?.[0]?.role_name}`;
      },
      flex: 0.65,
    },
    {
      field: "active_status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => (
        <StatusDropDown
          row={row}
          edit={false}
          handleChangeStatus={handleChangeStatus}
          styleProps={{ minWidth: "150px", maxWidth: "200px" }}
        />
      ),
    },
  ];

  const Modal = () => {
    const [btnLoading, setBtnLoading] = useState(false);

    const methods = useForm({
      resolver: yupResolver(USER_EMPLOYEE_PROJECT_ALLOCATION_SCHEMA),
    });
    const {
      handleSubmit,
      reset,
      control,
      setValue,
      formState: { errors },
    } = methods;

    const toggleEditMode = () => {
      setIsViewMode(false);
    };

    const handleFormSubmit = async (data) => {
      try {
        console.log(data);
        // return;
        setBtnLoading(true);
        let response;
        const params = {
          ...data,
          active_status: 0,
          ueser_create_id: user_create_id,
          project_allocation_name: rows?.first_name,
        };
        response = await axiosInstance.post("/projectAllocation", params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response?.data?.error === false) {
          toast.success(response?.data?.message);
          handleClose();
          loadInitialData();
        } else {
          toast.error("ERROR:" + response?.data?.message);
          console.error(response?.data?.message);
        }
      } catch (error) {
        console.error("Error creating account:", error);
      } finally {
        setBtnLoading(false);
      }
    };

    useEffect(() => {
      if (isViewMode) {
        reset();
      }
    }, [isViewMode, reset]);

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "0px",
            borderRadius: "5px 0 0 0",
            backgroundColor: "#F6F6F6",
            opacity: 1,
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "16px 24px",
              backgroundColor: "#F6F6F6",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "22px",
                fontWeight: 700,
                color: "#272625",
              }}
            >
              {selectedRow
                ? isViewMode
                  ? "View Employee Details"
                  : "Edit Employee Details"
                : "Create Project Allocation"}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                width: "24px",
                height: "24px",
                border: "1px solid #FF3B30",
              }}
            >
              <CloseIcon sx={{ color: "#FF3B30" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
          <Grid container spacing={{ xs: 5 }} sx={{ gap: 3 }}>
            <Grid size={6}>
              <FormInputDropdown
                name="projects_id"
                control={control}
                label={"Choose Project"}
                type="single"
                options={dropdownData?.activeProjects?.map((item) => ({
                  id: item?.projects_id,
                  name: item?.project_name,
                }))}
                errors={errors}
                defaultValue={selectedRow?.projects_id || ""}
                mandatory="true"
                isViewMode={isViewMode}
              />
            </Grid>
            <Grid size={6}>
              <FormInputDropdown
                name="role_id"
                control={control}
                label={"Choose Role"}
                type="single"
                options={dropdownData?.activeRoles?.map((item) => ({
                  id: item?.role_id,
                  name: item?.role_name,
                }))}
                errors={errors}
                defaultValue={selectedRow?.role_id || ""}
                mandatory="true"
                isViewMode={isViewMode}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isViewMode ? (
            <Button
              color="primary"
              variant="contained"
              // onClick={() => setIsViewMode(true)}
              onClick={toggleEditMode}
              sx={{ px: 2 }}
            >
              Edit
            </Button>
          ) : (
            <Button
              // disabled={!isValid}
              color="primary"
              variant="contained"
              onClick={handleSubmit(handleFormSubmit)}
              disabled={btnLoading}
              sx={{ px: 2 }}
            >
              {btnLoading ? (
                <CircularProgress color="#FFFFFF" size="20px" />
              ) : (
                "Save"
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "26px",
                lineHeight: "30px",
                marginRight: "16px",
                color: "#333333",
                textWrap: "nowrap",
              }}
            >
              <IconButton aria-label="arrow" onClick={() => navigate(-1)}>
                <ArrowLeft size={30} />
              </IconButton>
              Project Allocation
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewRecord}
              sx={{
                height: "40px",
                padding: "8px, 16px, 8px, 16px",
                borderRadius: "8px",
                backgroundColor: "#47A0DE",
                color: "#FFFFFF",
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "24px",
                gap: "10px",
                textTransform: "capitalize",
                width: "25%",
              }}
            >
              <Plus size={20} weight="bold" />
              New Record
            </Button>
          </Box>
          <Box sx={{ mb: 3 }}>
            {rows?.user_id && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 3,
                  bgcolor: "#F6F6F6",
                  pt: 1,
                  px: 2,
                  borderRadius: "5px",
                  justifyContent: "space-between",
                }}
              >
                <HeaderSummaryItem
                  heading={"Name"}
                  value={`${rows?.first_name} ${rows?.last_name}`}
                />
                <HeaderSummaryItem
                  heading={"Mobile No."}
                  value={rows?.mobile_no}
                />
                <HeaderSummaryItem heading={"User Id"} value={rows?.user_id} />
                <HeaderSummaryItem
                  heading={"Password"}
                  value={rows?.password}
                />
                <HeaderSummaryItem
                  heading={"End Date"}
                  value={rows?.end_date}
                />
              </Box>
            )}
          </Box>
          <GenerateDataTable
            rows={allocations || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.project_allocation_id}
          />
        </Grid>
      </Grid>
      <Modal />
    </>
  );
}
