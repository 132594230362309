import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import { theme } from "../../utils/theme";
import { FormHelperText } from "@mui/material";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function FormMultiSelectDropdown({
  options,
  defaultValue,
  name,
  control,
  label,
  setValue,
}) {
  console.log(defaultValue);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === "string" ? value.split(",") : value;
    setSelectedOptions(selectedValues);
    setValue(name, selectedValues);
  };
  useEffect(() => {
    if (defaultValue) {
      const defaultArray = Array.isArray(defaultValue)
        ? defaultValue
        : [defaultValue];
      const defaultLabels = options
        .filter((option) => defaultArray.includes(option.key))
        .map((option) => option.label);
      setSelectedOptions(defaultLabels);
    }
  }, [defaultValue, options]);
  useEffect(() => {
    setValue(name, selectedOptions);
  }, [selectedOptions]);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div>
            <InputLabel
              id="demo-multiple-checkbox-label"
              className="text-input-label"
              sx={{ fontSize: "14px !important", mb: 1 }}
            >
              {label}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedOptions}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                border: theme.colors.inputs,
                width: "100%",
                bgcolor: "#FFFFFF",
                height: 45,
                "&:hover": {
                  borderColor: theme.colors.inputFocus,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.colors.inputFocus,
                },
              }}
            >
              <MenuItem key={""} value={""}>
                <ListItemText primary={"--select--"} />
              </MenuItem>
              {options.map((item) => (
                <MenuItem key={item?.key} value={item?.label}>
                  <Checkbox checked={selectedOptions.includes(item?.label)} />
                  <ListItemText primary={item?.label} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ color: "red" }}>
              {error?.message || ""}
            </FormHelperText>
          </div>
        );
      }}
    />
  );
}
