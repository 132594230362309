import React, { createContext, useContext, useEffect, useState } from "react";

// Create Auth Context
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

// Create Auth Provider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState({});
  const [token, setToken] = useState("");

  useEffect(() => {
    const storedUserData = sessionStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const login = (data) => {
    setIsAuthenticated(true);
    sessionStorage.setItem("userData", JSON.stringify(data));
  };
  const logout = () => {
    setIsAuthenticated(false);
    setUserData(null);
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        userData,
        setUserData,
        token,
        setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
