import { Navigate, Outlet } from "react-router-dom";

// Protected Route component to check authentication
const ProtectedRoute = () => {
  const token = sessionStorage.getItem("token");

  // If no token, redirect to login
  if (!token) {
    return <Navigate to="/login" />;
  }

  // Otherwise, allow access to the protected route
  return <Outlet />;
};

export default ProtectedRoute;
