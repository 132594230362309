import React, { memo } from "react";
import { Box } from "@mui/material";
import { BASE_URL } from "../../../../api/axios";
import ImageMarkup from "../../../reusable/ImageMarkup";

const RenderFileViewer = memo(
  ({ selectedFile, btnLoading, zoom, rotation }) => {
    if (!selectedFile || btnLoading) return null;

    const { url, extension } = selectedFile;

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <Box
            component="img"
            src={url}
            alt="Selected File"
            sx={{
              width: "100%",
              transform: `scale(${zoom}) rotate(${rotation}deg)`,
            }}
          />
        );

      case "pdf":
        return (
          <Box sx={{ position: "relative", width: "100%", height: "400px" }}>
            <iframe
              src={url?.substring(0, 4) === "blob" ? url : BASE_URL + url}
              width="100%"
              height="100%"
              title="PDF Viewer"
              style={{ border: "none" }}
            />
          </Box>
        );

      case "xlsx":
      case "xls":
        return (
          <Box>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${
                BASE_URL + url
              }`}
              width="100%"
              height="400px"
              title="Excel File Viewer"
            />
          </Box>
        );

      default:
        return <p>Unsupported file format</p>;
    }
  }
);

export default RenderFileViewer;
