import { Box } from "@mui/material";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
// import Dashboard from "./components/screens/Dashboard";
import { ApplicationRoutes } from "./utils/Route";
import { UtitlityProvider } from "./context/UtilityContext";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./context/AuthContext";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <>
      <Router>
        <Toaster
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ApplicationRoutes />
              </LocalizationProvider>
            </AuthProvider>
          </Box>
        </Box>
      </Router>
    </>
  );
}

export default App;
