import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "../../../api/axios";
import { CONTRACTOR_SCHEMA } from "../../../utils/schema";
import { FormInputText } from "../../form-components/FormInputText";
import GenerateDataTable from "../../reusable/GenerateDataTable";
import { Plus } from "@phosphor-icons/react";
import SearchBar from "../../reusable/SearchBar";
import FormMultiSelectDropdown from "../../form-components/FormMultiSelectDropdown";

function Contractors() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [activeClients, setActiveClients] = useState([]);

  const loadInitialData = async () => {
    try {
      setLoading(true);
      setSelectedRow(null);

      const [contractorResponse, clientsResponse] = await Promise.all([
        axiosInstance.get("/indexContractor"),
        axiosInstance.get("/getActiveClients"),
      ]);

      if (contractorResponse?.data?.data?.length > 0) {
        const formattedContractorData = contractorResponse.data.data.map(
          (item, index) => ({
            index,
            ...item,
          })
        );
        setRows(formattedContractorData);
      }

      if (clientsResponse?.data?.data?.length > 0) {
        setActiveClients(clientsResponse?.data?.data);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = async (event, currentRow) => {
    const newStatus = event.target.value;
    if (newStatus == "EDIT") {
      handleOpen();
      setSelectedRow(currentRow);
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/contractorsSwitch/${currentRow?.contractors_id}`,
        {
          status: newStatus,
        }
      );

      if (response.data.error) {
        console.error(response.data.message);
        toast.error(response.data.message);
      } else {
        loadInitialData();
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error updating account status:", error);
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "S.No",
      valueGetter: (row) => row + 1,
    },
    {
      field: "contract_name",
      headerName: "Contactor Name",
      flex: 1,
    },
    {
      field: "contract_nick_name",
      headerName: "Nick Name",
      flex: 1,
    },
    {
      field: "clients",
      headerName: "Clients",
      flex: 1,

      renderCell: ({ row }) => {
        return row?.contractclientslist?.length > 0
          ? row.contractclientslist
              .map((item) => item?.clientslist?.[0]?.client_name)
              .filter(Boolean)
              .join(", ")
          : " - ";
      },
    },
    {
      field: "active_status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => (
        <Select
          value={row.active_status}
          onChange={(e) => handleChangeStatus(e, row)}
          displayEmpty
          style={{
            minWidth: "150px",
            maxWidth: "200px",
            height: "40px",
            borderRadius: "5px",
            background: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
            opacity: 1,
            fontSize: 16,
            fontWeight: 400,
            border: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
          }}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="1">Active</MenuItem>
          <MenuItem value="0">Inactive</MenuItem>
          {row.active_status == "0" && <MenuItem value="EDIT">Edit</MenuItem>}
        </Select>
      ),
    },
  ];

  const Modal = () => {
    const [btnLoading, setBtnLoading] = useState(false);
    const methods = useForm({ resolver: yupResolver(CONTRACTOR_SCHEMA) });
    const {
      handleSubmit,
      reset,
      control,
      setValue,
      formState: { errors },
    } = methods;

    const handleFormSubmit = async (data) => {
      try {
        setBtnLoading(true);
        let response;
        const clientIds = data?.client_id?.map((name) => {
          const selectedOption = activeClients.find(
            (option) => option.client_name === name
          );
          return selectedOption.client_id;
        });
        const uniqueclientIdsArray = [...new Set(clientIds)];

        if (selectedRow?.contractors_id) {
          const params = {
            client_id: uniqueclientIdsArray,
            contract_name: data.contract_name,
            contract_nick_name: data?.contract_nick_name,
            active_status: selectedRow?.active_status,
            contractors_id: selectedRow?.contractors_id,
          };
          response = await axiosInstance.post("/updateContractors", params);
        } else {
          const params = {
            contract_name: data.contract_name,
            contract_nick_name: data?.contract_nick_name,
            client_id: uniqueclientIdsArray,
            active_status: 0,
          };
          response = await axiosInstance.post("/contractorsCreate", params);
        }

        if (response?.data?.error === false) {
          toast.success(response?.data?.message);
          loadInitialData();
          handleClose();
        } else {
          toast.error("ERROR:" + response?.data?.message);
          console.error(response?.data?.message);
        }
      } catch (error) {
        console.error("Error creating account:", error);
      } finally {
        setBtnLoading(false);
      }
    };

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "0px",
            borderRadius: "5px 0 0 0",
            backgroundColor: "#F6F6F6",
            opacity: 1,
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "16px 24px",
              backgroundColor: "#F6F6F6",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "22px",
                fontWeight: 700,
                color: "#272625",
              }}
            >
              {selectedRow?.contractors_id
                ? "Edit Contactor"
                : "Create Contactor"}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                width: "24px",
                height: "24px",
                border: "1px solid #FF3B30",
              }}
            >
              <CloseIcon sx={{ color: "#FF3B30" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
          <Grid container columnSpacing={{ xs: 5 }} sx={{ gap: 3 }}>
            <Grid size={6}>
              <FormInputText
                name="contract_name"
                control={control}
                label="Contactor Name"
                errors={errors}
                mandatory="true"
                placeholder={"Enter Contactor Name"}
                defaultValue={selectedRow?.contract_name}
              />
            </Grid>
            <Grid size={6}>
              <FormInputText
                name="contract_nick_name"
                control={control}
                label="Nick Name"
                errors={errors}
                mandatory="true"
                placeholder={"Enter Nick Name"}
                defaultValue={selectedRow?.contract_nick_name}
              />
            </Grid>
            <Grid size={12}>
              <FormMultiSelectDropdown
                name="client_id"
                control={control}
                label={"Client Name"}
                options={activeClients?.map((item) => {
                  return { key: item?.client_id, label: item?.client_name };
                })}
                defaultValue={selectedRow?.contractclientslist[0]?.clientslist?.map(
                  (client) => client?.client_id
                )}
                mandatory="true"
                setValue={setValue}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(handleFormSubmit)}
          >
            {btnLoading ? (
              <CircularProgress color="#FFFFFF" size="20px" />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "26px",
                lineHeight: "30px",
                marginRight: "16px",
                color: "#333333",
              }}
            >
              Contractors
            </Typography>

            <SearchBar
              setLoading={setLoading}
              setRows={setRows}
              loadInitialData={loadInitialData}
              searchAPI={"searchContractors"}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpen}
              sx={{
                height: "40px",
                padding: "8px, 16px, 8px, 16px",
                borderRadius: "8px",
                backgroundColor: "#47A0DE",
                color: "#FFFFFF",
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "24px",
                gap: "10px",
                width: "25%",
                textTransform: "capitalize",
              }}
            >
              <Plus size={20} weight="bold" />
              New Record
            </Button>
          </Box>
          <GenerateDataTable
            rows={rows || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.contractors_id}
          />
        </Grid>
        {/* <Grid
          size={3}
          sx={{ gap: "16px", display: "flex", flexDirection: "column" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            sx={{
              height: "40px",
              padding: "8px, 16px, 8px, 16px",
              borderRadius: "8px",
              backgroundColor: "#47A0DE",
              color: "#FFFFFF",
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "24px",
              gap: "10px",
              textTransform: "capitalize",
            }}
          >
            <Plus size={20} weight="bold" />
            New Record
          </Button>
        </Grid> */}
      </Grid>
      <Modal />
    </>
  );
}

export default Contractors;
