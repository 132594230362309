import {
  Typography,
  Grid2 as Grid,
  IconButton,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  ArrowArcLeft,
  ArrowArcRight,
  ArrowCounterClockwise,
  Eraser,
  FloppyDisk,
  Pen,
  TrashSimple,
  X,
} from "@phosphor-icons/react";
import { useRef, useState } from "react";
import { colors } from "../../../../utils/constants";
import axiosInstance from "../../../../api/axios";
import ImageMarkup from "../../../reusable/ImageMarkup";
import toast from "react-hot-toast";

const MarkupEditor = ({ setMarkup, selectedFile, data, loadInitialData }) => {
  const canvasRef = useRef(null);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [eraseMode, setEraseMode] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleCancelClick = () => {
    handleResetClick();
    setEraseMode(true);
    canvasRef.current?.eraseMode(true);
    setMarkup(false);
  };

  const handleEraserClick = () => {
    setEraseMode(true);
    canvasRef.current?.eraseMode(true);
  };

  const handlePenClick = (color) => {
    setEraseMode(false);
    canvasRef.current?.eraseMode(false);
    setSelectedColor(color);
  };

  const handleSaveClick = async () => {
    const dataUrl = await canvasRef.current.exportImage("png");
    const blob = await fetch(dataUrl).then((res) => res.blob());
    const file = new File([blob], "canvas.png", { type: "image/png" });
    reassignImage(file);
  };

  const handleUndoClick = () => {
    canvasRef.current?.undo();
  };

  const handleRedoClick = () => {
    canvasRef.current?.redo();
  };

  const handleClearClick = () => {
    canvasRef.current?.clearCanvas();
  };

  const handleResetClick = () => {
    canvasRef.current?.resetCanvas();
  };
  const reassignImage = (uploadedFile) => {
    if (data?.template_check_list_id && uploadedFile) {
      setBtnLoading(true);
      const formData = new FormData();
      formData.append("re_assigned_images", uploadedFile);
      formData.append("re_assigned_description", null);
      formData.append("status_id", data?.status_id);
      formData.append("hop_image_upload_id", selectedFile?.hop_image_upload_id);
      formData.append("template_check_list_id", data?.template_check_list_id);
      axiosInstance
        .post("/reassignedImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          loadInitialData();
          handleCancelClick();
          toast.success(response?.data?.message);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message || error?.message);
        })
        .finally(() => setBtnLoading(false));
    }
  };

  return (
    <Box sx={{ px: 2 }}>
      <Typography
        variant="h6"
        sx={{
          px: 2,
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "19.6px",
          fontFamily: "var(--font-secondary)",
        }}
      >
        Markup Tools
      </Typography>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",  
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Grid container spacing={3}>
          <Grid size={8}>
            <ImageMarkup
              imageUrl={selectedFile?.url}
              canvasRef={canvasRef}
              selectedColor={selectedColor}
            />
          </Grid>
          <Grid size={4}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              {/* Color Palette */}
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                {colors.map((color) => (
                  <IconButton
                    key={color}
                    onClick={() => handlePenClick(color)}
                    disabled={eraseMode}
                    sx={{
                      backgroundColor: color,
                      border: "1px solid black",
                      width: 36,
                      height: 36,
                      "&:hover": { border: "2px solid black" },
                    }}
                  />
                ))}
              </Box>

              {/* Action Icons */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
                justifyContent={"space-evenly"}
              >
                <IconButton
                  onClick={() => handlePenClick(selectedColor)}
                  disabled={!eraseMode}
                >
                  <Pen size={20} color={selectedColor} />
                </IconButton>
                <IconButton
                  onClick={handleEraserClick}
                  disabled={eraseMode}
                  sx={{
                    color: eraseMode && "black",
                    "&:hover": { color: "black" },
                  }}
                >
                  <Eraser size={20} />
                </IconButton>
                <IconButton onClick={handleUndoClick}>
                  <ArrowArcLeft size={20} />
                </IconButton>
                <IconButton onClick={handleRedoClick}>
                  <ArrowArcRight size={20} />
                </IconButton>
                <IconButton onClick={handleClearClick}>
                  <TrashSimple size={20} />
                </IconButton>
                <IconButton onClick={handleResetClick}>
                  <ArrowCounterClockwise size={20} />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Action Buttons */}
        <Box display="flex" flexDirection="row" gap={1}>
          <Button
            size="small"
            variant="outlined"
            sx={{ border: "1px solid #47A0DE" }}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ bgcolor: "#47A0DE" }}
            size="small"
            onClick={handleSaveClick}
          >
            {btnLoading ? (
              <CircularProgress color="#FFFFFF" size="20px" />
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MarkupEditor;
