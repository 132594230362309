import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import {
  ACKNOWLEDGE_SCHEMA,
  JOB_STATUS_SCHEMA,
} from "../../../../utils/schema";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../../form-components/FormInputText";
import axiosInstance from "../../../../api/axios";
import toast from "react-hot-toast";
import { FormInputDropdown } from "../../../form-components/FormInputDropdown";

const StatusModal = ({
  open,
  setOpen,
  data,
  dropDownData,
  otherPayloadData,
  loadInitialData,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const methods = useForm({ resolver: yupResolver(JOB_STATUS_SCHEMA) });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;
  const handleClose = () => {
    setOpen(false);
  };
  const handleFormSubmit = async (submitData) => {
    try {
      let response;
      setBtnLoading(true);
      const params = {
        job_id: otherPayloadData?.job_id,
        project_id: otherPayloadData?.project_id,
        templates_id: data?.template_id,
        status_id: data?.new_status,
        template_check_list_id: data?.template_check_list_id,
        description: data?.description,
        short_description: data?.short_description,
        due_date: otherPayloadData?.due_date,
        submitter: +submitData?.submitter,
        reviewer: +submitData?.reviewer,
      };

      response = await axiosInstance.post("/hopreviewstore", params);
      if (response?.data?.error === false) {
        toast.success(response?.data?.message);
        loadInitialData();
        handleClose();
      } else {
        toast.error("ERROR:" + response?.data?.message);
        console.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error creating account:", error);
    } finally {
      reset();
      setBtnLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: "0px",
          borderRadius: "5px 0 0 0",
          backgroundColor: "#F6F6F6",
          opacity: 1,
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "16px 24px",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "22px",
              fontWeight: 700,
              color: "#272625",
            }}
          >
            Assign Status
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              width: "24px",
              height: "24px",
              border: "1px solid #FF3B30",
            }}
          >
            <CloseIcon sx={{ color: "#FF3B30" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
        <Grid
          container
          columnSpacing={{ xs: 5 }}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid size={6}>
            <FormInputDropdown
              name="submitter"
              control={control}
              label={"Submiter"}
              type="single"
              options={dropDownData?.submitterList?.map((item) => ({
                id: item?.ueser_create_id,
                name: item?.project_allocation_name,
              }))}
              errors={errors}
              defaultValue={""}
              mandatory="true"
            />
          </Grid>
          <Grid size={6}>
            <FormInputDropdown
              name="reviewer"
              control={control}
              label={"Reviewer"}
              type="single"
              options={dropDownData?.reviewerList?.map((item) => ({
                id: item?.ueser_create_id,
                name: item?.project_allocation_name,
              }))}
              errors={errors}
              defaultValue={""}
              mandatory="true"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          // disabled={!isValid}
          color="primary"
          variant="contained"
          onClick={handleSubmit(handleFormSubmit)}
        >
          {btnLoading ? (
            <CircularProgress color="#FFFFFF" size="20px" />
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default StatusModal;
