export const STATUS = [
  {
    id: "",
    name: "--select--",
  },
  {
    id: "1",
    name: "Activate",
  },
  {
    id: "0",
    name: "Inactive",
  },
];

export const colors = ["#DC143C", "#0F52BA", "#50C878", "#FFEF00", "#DA70D6", "#000000"];
