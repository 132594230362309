import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ArrowLeft, ChatText, Files, Repeat } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../api/axios";
import { ACKNOWLEDGE_SCHEMA } from "../../../../utils/schema";
import { FormInputDropdown } from "../../../form-components/FormInputDropdown";
import { FormInputText } from "../../../form-components/FormInputText";
import GenerateMultiSelectDataTable from "../../../reusable/GenerateMultiSelectDataTable";
import StatStatus from "./StatStatus";
import StatusModal from "./StatusModal";

function HopReview() {
  const { projectName, id } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [statusLoader, setStatusLoader] = useState({ id: null, status: false });
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [categoryType, setCategoryType] = useState("");
  const [dropDownData, setDropDownData] = useState({});
  const [jobDetails, setJobDetails] = useState([]);
  const [jobDetailsBkp, setJobDetailsBkp] = useState([]);
  const [multiselectedId, setMultiselectedId] = useState([]);
  const statusCount = { open: 0, closed: 0 };

  const loadInitialData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/getJobId/${projectName}/${id}`
      );
      if (response?.data?.data?.length > 0) {
        const formattedProjectsData = response?.data?.data.map(
          (item, index) => ({
            index,
            ...item,
            template_checklists:
              item?.template_checklists?.map((subItem, subIndex) => ({
                index: subIndex,
                ...subItem,
              })) || [],
          })
        );

        setJobDetails(formattedProjectsData);
        setJobDetailsBkp(formattedProjectsData);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  const loadDropDownData = async () => {
    try {
      setLoading(true);

      const [accountResponse, statusResponse, submitterList, reviewerList] =
        await Promise.all([
          axiosInstance.get("/getActiveAccounts"),
          axiosInstance.get("/getActiveStatus"),
          axiosInstance.get("/indexProjectAllocationSubmitter"),
          axiosInstance.get("/indexProjectAllocationReviewer"),
        ]);

      if (accountResponse?.data?.data?.length > 0) {
        const formattedProjectsData = accountResponse?.data?.data.map(
          (item, index) => ({
            index,
            ...item,
          })
        );
        setDropDownData((prev) => ({
          ...prev,
          activeAccountList: formattedProjectsData,
        }));
      }

      if (statusResponse?.data?.data?.length > 0) {
        setDropDownData((prev) => ({
          ...prev,
          activeStatusList: statusResponse?.data?.data,
        }));
      }
      if (submitterList?.data?.data?.length > 0) {
        setDropDownData((prev) => ({
          ...prev,
          submitterList: submitterList?.data?.data,
        }));
      }
      if (reviewerList?.data?.data?.length > 0) {
        setDropDownData((prev) => ({
          ...prev,
          reviewerList: reviewerList?.data?.data,
        }));
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadInitialData();
    loadDropDownData();
  }, []);

  const statusDropDownHandler = async (e, row) => {
    reset();
    const newStatus = e?.target?.value;
    try {
      if (row?.template_check_list_id) {
        setStatusLoader({ id: row?.template_check_list_id, status: true });
        const response = await axiosInstance.get(
          `/indexTemplateschecklistId/${row?.template_check_list_id}`
        );
        if (!response.data.error) {
          setSelectedRow({
            ...response?.data?.data?.[0],
            new_status: newStatus,
          });
          setOpen(true);
        }
      }
    } catch (error) {
      console.error("Invalid ID:", error);
    } finally {
      setStatusLoader({ id: null, status: false });
    }
  };
  const columns = [
    {
      field: "index",
      headerName: "S.No",
      valueGetter: (row) => row + 1,
      maxWidth: 75,
    },
    {
      field: "description",
      headerName: "Task Name",
      flex: 1,
      minWidth: 200,
      maxWidth: 350,
    },
    {
      field: "short_description",
      maxWidth: 50,
      flex: 1,
      renderCell: ({ row }) => (
        <Tooltip title={row?.short_description}>
          <ChatText size={15} />
        </Tooltip>
      ),
      renderHeader: () => <ChatText size={18} weight="bold" />,
    },
    {
      field: "submitter",
      headerName: "Submitter",
      renderCell: ({ row }) => {
        const submitter =
          row?.hop_review_list?.[0]?.submiter_name_list?.[0]?.first_name ||
          "NA";
        return submitter;
      },
    },
    {
      field: "reviewer",
      headerName: "Reviewer",
      renderCell: ({ row }) => {
        const reviewer =
          row?.hop_review_list?.[0]?.reviewer_name_list?.[0]?.first_name ||
          "NA";
        return reviewer;
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      renderCell: ({ row }) => row?.hop_review_list?.[0]?.due_date || "-",
    },
    {
      field: "days",
      headerName: "Days",
      maxWidth: 100,
      renderCell: ({ row }) => row?.hop_review_list?.[0]?.days || "0",
    },
    {
      field: "view_type",
      headerName: "Desc",
      maxWidth: 50,
      renderCell: ({ row }) =>
        row?.hop_review_list?.[0]?.submiterimage?.length || "0",
      renderHeader: () => <Files size={18} weight="bold" />,
    },
    {
      field: "iteration",
      headerName: "Desc",
      maxWidth: 50,
      renderCell: ({ row }) =>
        row?.hop_review_list?.[0]?.submiterimage?.length || "0",
      renderHeader: () => <Repeat size={18} weight="bold" />,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 150,
      renderCell: ({ row }) => {
        let menuValue = row?.hop_review_list?.[0]?.status_id;
        if (menuValue == undefined || menuValue == null) {
          menuValue = "";
        }
        return row?.template_check_list_id == statusLoader?.id &&
          statusLoader ? (
          <CircularProgress color="#EF7540" size="16px" />
        ) : (
          <Select
            value={menuValue || ""}
            onChange={(e) => statusDropDownHandler(e, row)}
            displayEmpty
            style={{
              width: "100%",
              height: "40px",
              borderRadius: "5px",
              opacity: 1,
              fontSize: 16,
              fontWeight: 400,
              border: "1px solid #EF7540",
            }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem value={""}>Choose Status</MenuItem>
            {dropDownData?.activeStatusList?.map((item) => (
              <MenuItem value={item?.status_id}>{item?.status_name}</MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];
  const methods = useForm({
    resolver: yupResolver(ACKNOWLEDGE_SCHEMA),
  });
  const { reset } = methods;

  useEffect(() => {
    if (categoryType == "") {
      setJobDetails(jobDetailsBkp);
    } else {
      const filteredJobs = jobDetailsBkp?.[0]?.template_checklists?.filter(
        (item) => item?.category == categoryType
      );
      setJobDetails((prev) => ({
        ...prev,
        [0]: {
          ...prev[0],
          template_checklists: filteredJobs,
        },
      }));
    }
  }, [categoryType]);
  const onRowSelectionModelChange = (newSelection) => {
    setMultiselectedId([...newSelection]);
  };
  dropDownData?.activeStatusList?.forEach((status) => {
    statusCount[status.status_name] = 0;
  });
  const statusMap = {};
  dropDownData?.activeStatusList?.forEach((status) => {
    statusMap[status.status_id] = status.status_name;
  });

  jobDetails?.[0]?.template_checklists?.forEach((row) => {
    const statusName = statusMap[row.status_id];
    if (statusName) {
      statusCount[statusName]++;
    }
    if (row.approved_at && row.na_approved_at) {
      statusCount["closed"]++;
    } else {
      statusCount["open"]++;
    }
  });
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              marginBottom: 2,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton aria-label="arrow" onClick={() => navigate(-1)}>
                <ArrowLeft size={24} weight="bold" />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  fontSize: "26px",
                  lineHeight: "30px",
                  marginRight: "16px",
                  color: "#333333",
                }}
              >
                {jobDetails?.[0]?.unique_job_identifier}
              </Typography>
            </Box>
            {multiselectedId?.length > 0 && (
              <Select
                // onChange={(e) => statusDropDownHandler(e, row)}
                displayEmpty
                style={{
                  minWidth: "150px",
                  height: "40px",
                  borderRadius: "5px",
                  opacity: 1,
                  fontSize: 16,
                  fontWeight: 400,
                  border: "1px solid #EF7540",
                  background: "#EF7540",
                  color: "white",
                }}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {dropDownData?.activeStatusList?.map((item) => (
                  <MenuItem value={item?.status_id}>
                    {item?.status_name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
          <StatStatus
            categoryType={categoryType}
            setCategoryType={setCategoryType}
            data={jobDetails?.[0]?.template_details?.categories}
            statusCount={statusCount}
          />
          <GenerateMultiSelectDataTable
            rows={jobDetails?.[0]?.template_checklists || []}
            columns={columns}
            onRowSelectionModelChange={onRowSelectionModelChange}
            loading={loading}
            getRowId={(row) => row?.template_check_list_id}
          />
        </Grid>
      </Grid>
      <StatusModal
        loadInitialData={loadInitialData}
        open={open}
        setOpen={setOpen}
        data={selectedRow}
        dropDownData={{
          submitterList: dropDownData?.submitterList,
          reviewerList: dropDownData?.reviewerList,
        }}
        otherPayloadData={jobDetails?.[0]}
      />
    </>
  );
}

export default HopReview;
