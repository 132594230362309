import * as yup from "yup";

export const LOGIN_SCHEMA = yup.object().shape({
  username: yup.string().required("Username is a required field"),
  password: yup.string().required("Password is a required field"),
});

export const ACCOUNT_SCHEMA = yup.object().shape({
  account_name: yup.string().required("Required"),
});

export const CLIENT_SCHEMA = yup.object().shape({
  client_name: yup.string().required("Required"),
  client_nick_name: yup.string().required("Required"),
});

export const CONTRACTOR_SCHEMA = yup.object().shape({
  contract_name: yup.string().required("Required"),
  contract_nick_name: yup.string().required("Required"),
});

export const CATEGORIES_SCHEMA = yup.object().shape({
  //   attachmenttype_id: yup
  //     .array()
  //     .of(
  //       yup.object().shape({
  //         key: yup.string().required(),
  //       })
  //     )
  //     .min(1, "Attachment is required"),
  categories_name: yup.string().required("Required"),
});

export const STATUS_SCHEMA = yup.object().shape({
  status_name: yup.string().required("Required"),
});

export const ROLE_SCHEMA = yup.object().shape({
  role_name: yup.string().required("Required"),
});
export const ACKNOWLEDGE_SCHEMA = yup.object().shape({
  acknowledgement_name: yup.string().required("Required"),
});
export const ATTACHMENT_SCHEMA = yup.object().shape({
  attachmenttype_name: yup.string().required("Required"),
});
export const PROJECTS_SCHEMA = yup.object().shape({
  accounts_id: yup.string().required("Required"),
  client_id: yup.string().required("Required"),
  project_name: yup.string().required("Required"),
});

export const USER_CLIENT_SCHEMA = yup.object().shape({
  client_id: yup.string().required("Required"),
  end_date: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  mobile_no: yup.string().required("Required"),
  password: yup.string().required("Required"),
  user_id: yup.string().required("Required"),
});
export const USER_EMPLOYEE_SCHEMA = yup.object().shape({
  end_date: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  mobile_no: yup.string().required("Required"),
  password: yup.string().required("Required"),
  user_id: yup.string().required("Required"),
});
export const USER_CLIENT_PROJECT_ALLOCATION_SCHEMA = yup.object().shape({
  accounts_id: yup.string().required("Required"),
  client_id: yup.string().required("Required"),
  projects_id: yup.string().required("Required"),
  role_id: yup.string().required("Required"),
});

export const USER_EMPLOYEE_PROJECT_ALLOCATION_SCHEMA = yup.object().shape({
  projects_id: yup.string().required("Required"),
  role_id: yup.string().required("Required"),
});

export const USER_CONTRACTOR_SCHEMA = yup.object().shape({
  contractors_id: yup.string().required("Required"),
  end_date: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  mobile_no: yup.string().required("Required"),
  password: yup.string().required("Required"),
  user_id: yup.string().required("Required"),
});
export const JOB_STATUS_SCHEMA = yup.object().shape({
  submitter: yup.string().required("Required"),
  reviewer: yup.string().required("Required"),
});
