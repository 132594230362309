import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  ArrowClockwise,
  ArrowCounterClockwise,
  ArrowSquareOut,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  Upload,
} from "@phosphor-icons/react";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import MarkupMenu from "../../../reusable/MarkupMenu";
import RenderFileViewer from "./RenderFileViewer";
import { useAuth } from "../../../../context/AuthContext";
import MarkupEditor from "./MarkupEditor";

function PreviewControls({
  selectedFile,
  setMarkup,
  handleFileUpload,
  markup,
  btnLoading,
}) {
  const {
    userData: { role_id },
  } = useAuth();
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const fileInputRef = useRef();
  const canvasRef = React.useRef(null);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const IMAGE_TYPES = ["jpg", "jpeg", "png", "gif"];
  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => (prevZoom > 0.2 ? prevZoom - 0.1 : prevZoom));
  };

  const handleRotateLeft = () => {
    setRotation((prevRotation) => prevRotation - 90);
  };

  const handleRotateRight = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  const handleOpenInNewTab = () => {
    if (selectedFile) {
      window.open(selectedFile, "_blank");
    }
  };

  const handleUploadInputClick = () => {
    fileInputRef.current.click();
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const threeDotOptions = [
    {
      name: "Do Markup",
      icon: null,
      handler: (row) => {
        setMarkup(true);
      },
    },
    {
      name: "Choose Markup",
      icon: null,
      handler: (row) => {
        handleUploadInputClick();
      },
    },
  ];
  const chooseMarkUpOptions = [
    {
      name: "Choose Markup",
      icon: null,
      handler: (row) => {
        handleUploadInputClick();
      },
    },
  ];
  useEffect(() => {
    if (selectedFile) {
      setZoom(1);
      setRotation(0);
      setMarkup(false);
    }
  }, [selectedFile]);
  return (
    <>
      {selectedFile ? (
        <RenderFileViewer
          selectedFile={selectedFile}
          btnLoading={btnLoading}
          zoom={zoom}
          rotation={rotation}
          markup={markup}
          canvasRef={canvasRef}
          strokeColor={selectedColor}
          setSelectedColor={setSelectedColor}
          selectedColor={selectedColor}
        />
      ) : (
        <Typography
          sx={{
            padding: "48px 24px",
            height: "300px",
            backgroundColor: "#D9D9D9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "var(--font-alternative)",
            fontSize: "16px",
          }}
        >
          No file selected
        </Typography>
      )}
      {btnLoading ? (
        <Box
          sx={{
            mb: 1,
            p: 2,
            width: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          alignItems={"center"}
          justifyContent={"center"}
          display={"flex"}
          sx={{ mt: 2 }}
          flexDirection={"column"}
          flexWrap={"wrap"}
        >
          <Stack
            display={"flex"}
            direction="row"
            spacing={2}
            className="upload-modal-control"
            alignItems={"center"}
          >
            <VisuallyHiddenInput
              type="file"
              accept="image/*, application/pdf, .xls, .xlsx"
              onChange={handleFileUpload}
              multiple={!markup}
              ref={fileInputRef}
              hidden
            />
            {/* SUBMITTER */}
            {role_id == 6 && (
              <IconButton aria-label="arrow" onClick={handleUploadInputClick}>
                <Upload size={20} />
              </IconButton>
            )}
            {/* REVIEWER */}
            {role_id == 5 && (
              <MarkupMenu
                options={
                  IMAGE_TYPES.includes(selectedFile?.extension?.toLowerCase())
                    ? threeDotOptions
                    : chooseMarkUpOptions
                }
              />
            )}
            <IconButton aria-label="arrow" onClick={handleZoomIn}>
              <MagnifyingGlassPlus size={16} />
            </IconButton>
            <IconButton aria-label="arrow" onClick={handleZoomOut}>
              <MagnifyingGlassMinus size={16} />
            </IconButton>
            <IconButton aria-label="arrow" onClick={handleRotateLeft}>
              <ArrowCounterClockwise size={16} />
            </IconButton>
            <IconButton aria-label="arrow" onClick={handleRotateRight}>
              <ArrowClockwise size={16} />
            </IconButton>
            <IconButton aria-label="arrow" onClick={handleOpenInNewTab}>
              <ArrowSquareOut size={16} />
            </IconButton>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default PreviewControls;
