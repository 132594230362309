import { Box, Typography } from "@mui/material";
import React from "react";

function HeaderSummaryItem({ heading, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
        px: 2,
        py: 3
      }}
    >
      <Typography variant="p" className="project-allocation-heading">
        {heading}
      </Typography>
      <Typography variant="p" className="project-allocation-value">
        {value}
      </Typography>
    </Box>
  );
}

export default HeaderSummaryItem;
