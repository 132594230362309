import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Plus } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../api/axios";
import {
  USER_EMPLOYEE_SCHEMA
} from "../../../../utils/schema";
import { FormInputDate } from "../../../form-components/FormInputDate";
import { FormInputText } from "../../../form-components/FormInputText";
import ImageUpload from "../../../form-components/ImageUpload";
import GenerateDataTable from "../../../reusable/GenerateDataTable";
import SearchBar from "../../../reusable/SearchBar";
import StatusDropDown from "../../../reusable/StatusDropDown";
import ThreeDotMenu from "../../../reusable/ThreeDotMenu";

export function Employees() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isViewMode, setIsViewMode] = useState(true);
  const [activeEmployees, setActiveEmployees] = useState([]);

  const navigate = useNavigate();

  const loadInitialData = () => {
    setLoading(true);
    setSelectedRow(null);
    axiosInstance
      .get(`/getindexConractorsUsersCreate`)
      .then((response) => {
        if (response.data.error === false) {
          const formattedData = response.data.data.map((account, index) => ({
            index,
            ...account,
            full_name: `${account.first_name} ${account.last_name}`,
          }));

          setRows(formattedData);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const loadActiveEmployees = () => {
    setLoading(true);
    setSelectedRow(null);
    axiosInstance
      .get(`/getActiveClients`)
      .then((response) => {
        if (response.data.error === false) {
          setActiveEmployees(response.data?.data);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadInitialData();
    loadActiveEmployees();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsViewMode(false);
    setUserDetails(null);
  };

  const handleNewRecord = () => {
    setSelectedRow(null);
    setUserDetails(null);
    setIsViewMode(false);
    setOpen(true);
  };

  const handleViewbutton = async (row) => {
    try {
      const response = await axiosInstance.get(
        `/getUsersCreateDetailsId/${row.ueser_create_id}`
      );
      if (!response.data.error) {
        setUserDetails(response.data.data);
        setOpen(true);
      } else {
        toast.error("Error fetching user details");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error("An error occurred while fetching user details");
    }
  };

  const handleChangeStatus = async (event, currentRow) => {
    const newStatus = event.target.value;
    if (newStatus == "EDIT") {
      setSelectedRow(currentRow);
      setIsViewMode(false);

      handleOpen();
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/usersCreateSwitch/${currentRow?.ueser_create_id}`,
        {
          status: newStatus,
        }
      );

      if (response.data.error) {
        console.error(response.data.message);
        toast.error(response.data.message);
      } else {
        loadInitialData();
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error updating account status:", error);
    }
  };
  const viewHandler = (rowData) => {
    setSelectedRow(rowData);
    setIsViewMode(true);
    setOpen(true);
  };

  const handleProjectAllocation = (row) => {
    navigate(`/users/employees-details/project-allocation/${row.ueser_create_id}`);
  };

  const threeDotOptions = [
    {
      name: "View",
      icon: null,
      handler: viewHandler,
    },
    {
      name: "Project Allocation",
      icon: null,
      handler: handleProjectAllocation,
    },
  ];
  const columns = [
    {
      field: "index",
      headerName: "S.No",
      flex: 0.25,
      valueGetter: (value) => value + 1,
    },
    {
      field: "full_name",
      headerName: "Full Name",
      valueGetter: (value, row) => {
        return `${row.first_name || ""} ${row.last_name || ""}`;
      },
      flex: 1,
    },
    {
      field: "user_id",
      headerName: "User Id",
      flex: 1,
    },
    {
      field: "end_date",
      headerName: "End Date",
      flex: 1,
      flex: 0.65,
    },
    {
      field: "active_status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => (
        <StatusDropDown
          row={row}
          handleChangeStatus={handleChangeStatus}
          edit={false}
          styleProps={{ minWidth: "150px", maxWidth: "200px" }}
        />
      ),
    },
    {
      field: "view_type",
      headerName: "More",
      flex: 0.25,
      renderCell: ({ row }) => (
        <ThreeDotMenu options={threeDotOptions} row={row} edit={false} />
      ),
    },
  ];

  const Modal = () => {
    const [btnLoading, setBtnLoading] = useState(false);

    const methods = useForm({ resolver: yupResolver(USER_EMPLOYEE_SCHEMA) });
    const {
      handleSubmit,
      reset,
      control,
      setValue,
      formState: { errors },
    } = methods;

    const toggleEditMode = () => {
      setIsViewMode(false);
    };

    const handleFormSubmit = async (data) => {
      try {
        setBtnLoading(true);
        let response;
        if (selectedRow?.client_id) {
          const params = {
            client_name: data.client_name,
            active_status: selectedRow?.active_status,
            client_nick_name: data?.client_nick_name,
            client_id: selectedRow?.client_id,
          };
          response = await axiosInstance.post("/updateClients", params);
        } else {
          const params = {
            ...data,
            title: "Mr",
            active_status: 1,
            employee_default_id: 1,
          };
          response = await axiosInstance.post("/usercreated", params, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }

        if (response?.data?.error === false) {
          toast.success(response?.data?.message);
          handleClose();
          loadInitialData();
        } else {
          toast.error("ERROR:" + response?.data?.message);
          console.error(response?.data?.message);
        }
      } catch (error) {
        console.error("Error creating account:", error);
      } finally {
        setBtnLoading(false);
      }
    };

    useEffect(() => {
      if (isViewMode) {
        reset();
      }
    }, [isViewMode, reset]);

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "0px",
            borderRadius: "5px 0 0 0",
            backgroundColor: "#F6F6F6",
            opacity: 1,
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "16px 24px",
              backgroundColor: "#F6F6F6",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "22px",
                fontWeight: 700,
                color: "#272625",
              }}
            >
              {selectedRow
                ? isViewMode
                  ? "View Employee Details"
                  : "Edit Employee Details"
                : "Create Employee Details"}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                width: "24px",
                height: "24px",
                border: "1px solid #FF3B30",
              }}
            >
              <CloseIcon sx={{ color: "#FF3B30" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
          <Grid container spacing={{ xs: 5 }} sx={{ gap: 3 }}>
            <Grid size={12}>
              <ImageUpload
                name="image_upload"
                control={control}
                label="Profile Photo"
                errors={errors}
                mandatory="true"
                defaultValue={selectedRow?.users_image}
                setValue={setValue}
                isViewMode={isViewMode}
              />
            </Grid>
            {/* <Grid size={6}>
              <FormInputDropdown
                name="client_id"
                control={control}
                label={"Client Name"}
                type="single"
                options={activeEmployees?.map((item) => ({
                  id: item?.client_id,
                  name: item?.client_name,
                }))}
                errors={errors}
                defaultValue={selectedRow?.client_id || ""}
                mandatory="true"
                isViewMode={isViewMode}
              />
            </Grid> */}
            <Grid size={6}>
              <FormInputText
                name="first_name"
                control={control}
                label="First Name"
                errors={errors}
                mandatory="true"
                placeholder={"Enter First Name"}
                defaultValue={selectedRow?.first_name}
                isViewMode={isViewMode}
              />
            </Grid>
            <Grid size={6}>
              <FormInputText
                name="last_name"
                control={control}
                label="Last Name"
                errors={errors}
                mandatory="true"
                placeholder={"Enter Last Name"}
                defaultValue={selectedRow?.last_name}
                isViewMode={isViewMode}
              />
            </Grid>

            {/* <Grid size={6}>
              <FormInputText
                name="title"
                control={control}
                label="Title"
                errors={errors}
                mandatory="true"
                placeholder={"Enter Title"}
                defaultValue={selectedRow?.title}
                disabled={isViewMode}
              />
            </Grid> */}

            <Grid size={6}>
              <FormInputText
                name="user_id"
                control={control}
                label="User Id"
                errors={errors}
                mandatory="true"
                placeholder={"Enter User Id"}
                defaultValue={selectedRow?.user_id}
                isViewMode={isViewMode}
              />
            </Grid>
            <Grid size={6}>
              <FormInputText
                name="password"
                control={control}
                label="Password"
                errors={errors}
                type="password"
                mandatory="true"
                placeholder={"Enter Password"}
                defaultValue={selectedRow?.password}
                isViewMode={isViewMode}
              />
            </Grid>
            <Grid size={6}>
              <FormInputText
                name="mobile_no"
                control={control}
                label="Mobile Number"
                errors={errors}
                mandatory="true"
                placeholder={"Enter Mobile Number"}
                defaultValue={selectedRow?.mobile_no}
                isViewMode={isViewMode}
              />
            </Grid>

            <Grid size={6}>
              <FormInputDate
                name="end_date"
                control={control}
                label="End Date"
                type="date"
                errors={errors}
                defaultValue={selectedRow?.end_date}
                mandatory="true"
                isViewMode={isViewMode}
              />
            </Grid>
            {/* <Grid size={6}>
              <FormInputText
                name="active_status"
                control={control}
                label="Select Active/Deactive"
                errors={errors}
                mandatory="true"
                placeholder={"Enter Active Status"}
                defaultValue={selectedRow?.active_status}
                disabled={isViewMode}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          {isViewMode ? (
            <Button
              color="primary"
              variant="contained"
              // onClick={() => setIsViewMode(true)}
              onClick={toggleEditMode}
              sx={{ px: 2 }}
            >
              Edit
            </Button>
          ) : (
            <Button
              // disabled={!isValid}
              color="primary"
              variant="contained"
              onClick={handleSubmit(handleFormSubmit)}
              disabled={btnLoading}
              sx={{ px: 2 }}
            >
              {btnLoading ? (
                <CircularProgress color="#FFFFFF" size="20px" />
              ) : (
                "Save"
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "26px",
                lineHeight: "30px",
                marginRight: "16px",
                color: "#333333",
                textWrap: "nowrap",
              }}
            >
              Employee Details
            </Typography>
            <SearchBar
              setLoading={setLoading}
              setRows={setRows}
              loadInitialData={loadInitialData}
              searchAPI={"searchClients"}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewRecord}
              sx={{
                height: "40px",
                padding: "8px, 16px, 8px, 16px",
                borderRadius: "8px",
                backgroundColor: "#47A0DE",
                color: "#FFFFFF",
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "24px",
                gap: "10px",
                textTransform: "capitalize",
                width: "25%",
              }}
            >
              <Plus size={20} weight="bold" />
              New Record
            </Button>
          </Box>
          <GenerateDataTable
            rows={rows || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.ueser_create_id}
          />
        </Grid>
        {/* <Grid
          size={3}
          sx={{ gap: "16px", display: "flex", flexDirection: "column" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleNewRecord}
            sx={{
              height: "40px",
              padding: "8px, 16px, 8px, 16px",
              borderRadius: "8px",
              backgroundColor: "#47A0DE",
              color: "#FFFFFF",
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "24px",
              gap: "10px",
              textTransform: "capitalize",
            }}
          >
            <Plus size={20} weight="bold" />
            New Record
          </Button>
         </Grid> */}
      </Grid>
      <Modal />
    </>
  );
}
