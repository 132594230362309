import React, { memo, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { Controller } from "react-hook-form";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { styled } from "@mui/material/styles";

const ImageUpload = ({
  name,
  control,
  label,
  setValue,
  status,
  defaultValue,
  mandatory,
  loadInitialData,
  isViewMode = false,
}) => {
  const aRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setImageUrl(defaultValue);
    }
  }, [defaultValue]);
  const fileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      setValue(name, file);
      setSelectedImage({ name: file });
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const upload = () => {
    if (selectedImage?.name) {
      setLoader(true);
      const formData = new FormData();
      formData.append("app_category_image", selectedImage?.name);
      formData.append("category_id", name);

      axios
        .post("/imageUpdateAppCategory", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (!response?.data?.error) {
            setImageUrl(response?.data?.data);
            loadInitialData();
            toast.success(response?.data?.message);
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });
            toast.error(errDesc || response?.data?.message);
          }
          setLoader(false);
        })
        .catch(function (error) {
          let msg = error;
          if (error?.code == "ERR_BAD_REQUEST") {
            msg = error?.response?.data?.message;
          } else {
            msg = error?.message;
          }
          toast.error(msg);
          setLoader(false);
        });
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: 2,
          }}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              width={80}
              height={80}
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <Avatar sx={{ width: 80, height: 80 }} />
          )}

          {!isViewMode && (
            <Box sx={{ gap: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  fontFamily: "var(--font-primary)",
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "#333333",
                }}
              >
                Profile Photo
              </Typography>
              <Button
                variant="text"
                component="label"
                color="primary"
                sx={{
                  fontFamily: "var(--font-primary)",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "22px",
                  color: "#EF7540",
                  textTransform: "capitalize",
                }}
                // startIcon={<PhotoCameraIcon />}
              >
                Upload
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={fileHandler}
                />
                {/* <input hidden accept="image/*" type="file" /> */}
              </Button>
            </Box>
          )}
        </Box>
      )}
    />
  );
};
export default memo(ImageUpload);
