import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, FormControlLabel, Switch } from "@mui/material";

const paginationModel = { page: 0, pageSize: 10 };

export default function GenerateMultiSelectDataTable({
  rows,
  columns,
  loading,
  onRowSelectionModelChange,
  getRowId,
}) {
  columns = columns?.map((col) => {
    return {
      ...col,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      cellClassName: "super-app-theme--cell",
    };
  });
  const styles = {
    // flex: 1,
    border: 1,
    borderColor: "#E6E6E6",
    borderRadius: 2,
    "& .super-app-theme--header": {
      fontFamily: "var(--font-secondary)",
      fontSize: 16,
      fontWeight: 600,
    },
    "& .super-app-theme--cell": {
      color: "#636363",
      fontWeight: "500",
      fontSize: 15,
      fontFamily: "var(--font-alternative)",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": { fontWeight: 600 },
    "& .MuiSvgIcon-root": {
      width: "0.75em",
      height: "0.75em",
    },
  };

  const options = {
    responsive: "scroll",
  };

  return (
    <div
      style={{
        width: "100%",
        // flex: 1,
        overflowX: "auto",
        marginTop: 2,
      }}
      className="generate-data-table"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[10, 20]}
        checkboxSelection={true}
        sx={styles}
        autoHeight={true}
        disableColumnResize={true}
        disableColumnMenu
        disableRowSelectionOnClick
        disableSelectionOnClick
        loading={loading}
        getRowId={getRowId}
        options={options}
        disableMultipleRowSelection={false}
        onRowSelectionModelChange={onRowSelectionModelChange}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
          },
        }}
      />
    </div>
  );
}
