import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "../../../api/axios";
import { PROJECTS_SCHEMA } from "../../../utils/schema";
import { FormInputText } from "../../form-components/FormInputText";
import GenerateDataTable from "../../reusable/GenerateDataTable";
import { Plus } from "@phosphor-icons/react";
import SearchBar from "../../reusable/SearchBar";
import { FormInputDropdown } from "../../form-components/FormInputDropdown";

function Projects() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownData, setDropDownData] = useState({
    activeAccountList: [],
    activeClientList: [],
  });

  const loadInitialData = () => {
    setLoading(true);
    setSelectedRow(null);
    axiosInstance
      .get(`/indexadd`)
      .then((response) => {
        if (response.data.error === false) {
          const formattedData = response.data.data.map((item, index) => ({
            index,
            ...item,
          }));
          setRows(formattedData);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const loadDropDownData = async () => {
    try {
      setLoading(true);
      setSelectedRow(null);

      const [accountResponse, clientsResponse] = await Promise.all([
        axiosInstance.get("/getActiveAccounts"),
        axiosInstance.get("/getActiveClients"),
      ]);

      if (accountResponse?.data?.data?.length > 0) {
        setDropDownData((prev) => ({
          ...prev,
          activeAccountList: accountResponse?.data?.data,
        }));
      }

      if (clientsResponse?.data?.data?.length > 0) {
        setDropDownData((prev) => ({
          ...prev,
          activeClientList: clientsResponse?.data?.data,
        }));
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInitialData();
    loadDropDownData();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = async (event, currentRow) => {
    const newStatus = event.target.value;
    if (newStatus == "EDIT") {
      handleOpen();
      setSelectedRow(currentRow);
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/projectsSwitch/${currentRow?.projects_id}`,
        {
          status: newStatus,
        }
      );

      if (response.data.error) {
        console.error(response.data.message);
        toast.error(response.data.message);
      } else {
        loadInitialData();
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error("Error updating account status:", error);
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "S.No",
      valueGetter: (row) => row + 1,
    },
    {
      field: "project_name",
      headerName: "Projects Name",
      flex: 1,
    },
    {
      field: "account_name",
      headerName: "Account Name",
      flex: 1,
      valueGetter: (value, row) => `${row?.accountslist?.[0]?.account_name}`,
    },
    {
      field: "client_name",
      headerName: "Client Name	",
      flex: 1,
      valueGetter: (value, row) => `${row.clientslist?.[0]?.client_name}`,
    },
    {
      field: "active_status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => (
        <Select
          value={row.active_status}
          onChange={(e) => handleChangeStatus(e, row)}
          displayEmpty
          style={{
            minWidth: "125px",
            maxWidth: "300px",
            height: "40px",
            borderRadius: "5px",
            background: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
            opacity: 1,
            fontSize: 16,
            fontWeight: 400,
            border: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
          }}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="1">Active</MenuItem>
          <MenuItem value="0">Inactive</MenuItem>
          {row.active_status == "0" && <MenuItem value="EDIT">Edit</MenuItem>}
        </Select>
      ),
    },
  ];

  const Modal = () => {
    const [btnLoading, setBtnLoading] = useState(false);

    const methods = useForm({ resolver: yupResolver(PROJECTS_SCHEMA) });
    const {
      handleSubmit,
      reset,
      control,
      setValue,
      formState: { errors },
    } = methods;
    const handleFormSubmit = async (data) => {
      try {
        let response;
        setBtnLoading(true);
        if (selectedRow?.projects_id) {
          const params = {
            project_name: data.project_name,
            active_status: selectedRow?.active_status,
            projects_id: selectedRow?.projects_id,
            accounts_id: data.accounts_id,
            client_id: data.client_id,
          };
          response = await axiosInstance.post("/updateAddProjects", params);
        } else {
          const params = {
            project_name: data.project_name,
            accounts_id: data.accounts_id,
            client_id: data.client_id,
            active_status: 0,
          };
          response = await axiosInstance.post("/projects", params);
        }

        if (response?.data?.error === false) {
          toast.success(response?.data?.message);
          loadInitialData();
          handleClose();
        } else {
          toast.error("ERROR:" + response?.data?.message);
          console.error(response?.data?.message);
        }
      } catch (error) {
        console.error("Error creating account:", error);
      } finally {
        setBtnLoading(false);
      }
    };
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "0px",
            borderRadius: "5px 0 0 0",
            backgroundColor: "#F6F6F6",
            opacity: 1,
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "16px 24px",
              backgroundColor: "#F6F6F6",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "22px",
                fontWeight: 700,
                color: "#272625",
              }}
            >
              {selectedRow?.projects_id ? "Edit Projects" : "Create Projects"}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                width: "24px",
                height: "24px",
                border: "1px solid #FF3B30",
              }}
            >
              <CloseIcon sx={{ color: "#FF3B30" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
          <Grid container columnSpacing={{ xs: 5 }} sx={{ gap: 2 }}>
            <Grid size={12}>
              <FormInputText
                name="project_name"
                control={control}
                label="Projects Name"
                errors={errors}
                mandatory="true"
                placeholder={"Enter Projects Name"}
                defaultValue={selectedRow?.project_name}
              />
            </Grid>
            <Grid size={12}>
              <FormInputDropdown
                name="accounts_id"
                control={control}
                label={"Account Name"}
                type="single"
                options={dropdownData?.activeAccountList?.map((item) => ({
                  id: item?.accounts_id,
                  name: item?.account_name,
                }))}
                errors={errors}
                defaultValue={selectedRow?.accounts_id}
                mandatory="true"
              />
            </Grid>
            <Grid size={12}>
              <FormInputDropdown
                name="client_id"
                control={control}
                label={"Client Name"}
                type="single"
                options={dropdownData?.activeClientList?.map((item) => ({
                  id: item?.client_id,
                  name: item?.client_name,
                }))}
                errors={errors}
                defaultValue={selectedRow?.client_id}
                mandatory="true"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(handleFormSubmit)}
          >
            {btnLoading ? (
              <CircularProgress color="#FFFFFF" size="20px" />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "26px",
                lineHeight: "30px",
                marginRight: "16px",
                color: "#333333",
              }}
            >
              Projects
            </Typography>

            <SearchBar
              setLoading={setLoading}
              setRows={setRows}
              loadInitialData={loadInitialData}
              searchAPI={"searchProjects"}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpen}
              sx={{
                height: "40px",
                padding: "8px, 16px, 8px, 16px",
                borderRadius: "8px",
                backgroundColor: "#47A0DE",
                color: "#FFFFFF",
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "24px",
                gap: "10px",
                textTransform: "capitalize",
                width: "25%",
              }}
            >
              <Plus size={20} weight="bold" />
              New Record
            </Button>
          </Box>
          <GenerateDataTable
            rows={rows || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.projects_id}
          />
        </Grid>
      </Grid>
      <Modal />
    </>
  );
}

export default Projects;
