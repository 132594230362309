import { Route, Routes } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Accounts from "../components/screens/accounts/Accounts";
import Acknowledgement from "../components/screens/acknowledgement/Acknowledgement";
import AddJobs from "../components/screens/add_jobs/AddJobs";
import AttachmentType from "../components/screens/attachment_type/AttachmentType";
import Categories from "../components/screens/categories/Categories";
import Clients from "../components/screens/clients/Clients";
import Contractors from "../components/screens/contractors/Contractors";
import Dashboard from "../components/screens/Dashboard";
import Login from "../components/screens/login/Login";
import Projects from "../components/screens/projects/Projects";
import Roles from "../components/screens/roles/Roles";
import Status from "../components/screens/status/Status";
import { Clients as UserClients } from "../components/screens/users/clients/Clients";
import { Contractors as UserContractors } from "../components/screens/users/contractors/Contractors";
import { EmployeeProjectAllocation } from "../components/screens/users/employees/EmployeeProjectAllocation";
import { Employees } from "../components/screens/users/employees/Employees";
import ProtectedRoute from "./ProtectedRoutes";
import { ClientProjectAllocation } from "../components/screens/users/clients/ClientProjectAllocation";
import { ContractorProjectAllocation } from "../components/screens/users/contractors/ContractorProjectAllocation";
import HopReview from "../components/screens/add_jobs/hop_review/HopReview";
import Jobs from "../components/screens/jobs/Jobs";
import UserHopReview from "../components/screens/user_hop_review/hop_review/UserHopReview";

export const ApplicationRoutes = () => {
  return (
    <Routes>
      {/* Public route: Login */}
      <Route path="/login" element={<Login />} />

      {/* Secured routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Layout />}>
          <Route element={<Dashboard />} />
          {/* Config related routes */}
          <Route path="config/accounts" element={<Accounts />} />
          <Route path="config/clients" element={<Clients />} />
          <Route path="config/contractors" element={<Contractors />} />
          <Route path="config/categories" element={<Categories />} />
          <Route path="config/status" element={<Status />} />
          <Route path="config/roles" element={<Roles />} />
          <Route path="config/acknowledgement" element={<Acknowledgement />} />
          <Route path="config/projects" element={<Projects />} />
          <Route path="config/attachment-type" element={<AttachmentType />} />

          <Route path="users/employees-details" element={<Employees />} />
          <Route path="users/clients-details" element={<UserClients />} />
          <Route
            path="users/contractors-details"
            element={<UserContractors />}
          />
          <Route
            path="users/employees-details/project-allocation/:user_create_id"
            element={<EmployeeProjectAllocation />}
          />
          <Route
            path="users/clients-details/project-allocation/:user_create_id"
            element={<ClientProjectAllocation />}
          />
          <Route
            path="users/contractors-details/project-allocation/:user_create_id"
            element={<ContractorProjectAllocation />}
          />
          <Route path="add-jobs" element={<AddJobs />} />
          <Route
            path="add-jobs/hop-review/:templateId/:projectName/:id"
            element={<HopReview />}
          />
          <Route path="jobs/:id" element={<Jobs />} />
          <Route
            path="jobs/user-hop-review/:project_id/:job_id/:user_create_id"
            element={<UserHopReview />}
          />
          {/* <Route
            path="users/clients-project-allocation"
            element={<ProjectAllocation />}
          /> */}
        </Route>
      </Route>
    </Routes>
  );
};
