import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../api/axios";
import { USER_CLIENT_PROJECT_ALLOCATION_SCHEMA } from "../../../utils/schema";
import { FormInputDropdown } from "../../form-components/FormInputDropdown";
import GenerateDataTable from "../../reusable/GenerateDataTable";
import SearchBar from "../../reusable/SearchBar";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { styled } from "@mui/material/styles";
import ThreeDotMenu from "../../reusable/ThreeDotMenu";

export function capitalizeFirstLetter(string) {
  if (!string) return string;
  let result = string.replace(/_/g, " ");

  result = result
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return result;
}
function AddJobs() {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [jobColumns, setJobColumns] = useState([]);
  const [jobFields, setJobFields] = useState([]);
  const [dropDownData, setDropDownData] = useState({});
  const [btnLoader, setBtnLoader] = useState({ type: null, loading: false });

  const loadDropDownData = async () => {
    try {
      setLoading(true);

      const [projectResponse, templateResponse] = await Promise.all([
        axiosInstance.get("/getActiveAllProjects"),
        axiosInstance.get("/getActiveTemplates"),
      ]);

      if (projectResponse?.data?.data?.length > 0) {
        const formattedProjectsData = projectResponse?.data?.data.map(
          (item, index) => ({
            index,
            ...item,
          })
        );
        setDropDownData((prev) => ({
          ...prev,
          activeProjects: formattedProjectsData,
        }));
      }

      if (templateResponse?.data?.data?.length > 0) {
        setDropDownData((prev) => ({
          ...prev,
          activeTemplateList: templateResponse?.data?.data,
        }));
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDropDownData();
  }, []);

  const threeDotOptions = [
    {
      name: "View",
      icon: null,
      handler: (row) => {
        navigate(
          `/add-jobs/hop-review/${row?.templates_id}/${row?.project_name}/${row?.id}`
        );
      },
    },
  ];
  const columns = [
    {
      field: "index",
      headerName: "S.No",
      valueGetter: (row) => row + 1,
    },
    {
      field: "project_name",
      headerName: "Project Name",
      // flex: 1,
      minWidth: 150,
    },
    {
      field: "template_name",
      headerName: "Template Name",
      // flex: 1,
      minWidth: 150,
    },
    {
      field: "account_name",
      headerName: "Account Name",
      // flex: 1,
      minWidth: 150,
    },
    {
      field: "clients_name",
      headerName: "Client Name",
      // flex: 0.75,
      minWidth: 150,
    },
    ...jobFields,
    {
      field: "view_type",
      headerName: "More",
      flex: 1,
      renderCell: ({ row }) => (
        <ThreeDotMenu options={threeDotOptions} row={row} />
      ),
    },
  ];
  const methods = useForm({
    resolver: yupResolver(USER_CLIENT_PROJECT_ALLOCATION_SCHEMA),
  });
  const {
    control,
    watch,
    formState: { errors },
  } = methods;

  const selectedProjectId = watch("projects_id");
  const selectedTemplateId = watch("templates_id");
  let projectList = dropDownData?.activeProjects;
  let templateList = dropDownData?.activeTemplateList;

  projectList = projectList?.map((item) => ({
    id: item?.projects_id,
    name: item?.project_name,
  }));

  templateList = templateList?.filter(
    (item) => item?.projects_id == selectedProjectId
  );

  templateList = templateList?.map((item) => ({
    id: item?.templates_id,
    name: item?.templates_name + "-" + item?.version,
  }));

  let filteredAccountObject = dropDownData?.activeTemplateList?.find(
    (item) => item?.templates_id == selectedTemplateId
  );

  function parseKey(string) {
    let result = string?.toLowerCase()?.replace(/\s+/g, "_");

    return result;
  }
  const fetchData = async () => {
    if (selectedTemplateId) {
      try {
        setLoading(true);
        const [jobFieldsResponse, jobColumnResponse] = await Promise.all([
          axiosInstance.get("/indexActiveJobFields"),
          axiosInstance.get(`/getJobCoulumnId/${selectedTemplateId}`),
        ]);

        if (jobFieldsResponse?.data?.data?.length > 0) {
          const formatedDataHeader = jobFieldsResponse?.data?.data?.map(
            (item) => ({
              field: parseKey(item?.field_name),
              headerName: capitalizeFirstLetter(item?.field_name),
              // flex: 1,
              minWidth: 150,
            })
          );
          setJobFields(formatedDataHeader);
        }

        if (jobColumnResponse?.data?.data?.length > 0) {
          const formattedData = jobColumnResponse.data.data.map(
            (item, index) => ({
              index,
              ...item,
            })
          );
          setJobColumns(formattedData);
        }
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedTemplateId]);

  const downloadHandler = () => {
    const accountId = filteredAccountObject?.accounts_id;
    const clientId = filteredAccountObject?.client_id;
    if (selectedProjectId && selectedTemplateId && accountId && clientId) {
      setBtnLoader({ type: "DOWNLOAD", loading: true });
      axiosInstance
        .get(
          `/download-job-excel/${selectedProjectId}/${selectedTemplateId}/${accountId}/${clientId}`,
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "jobs.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
        })
        .finally(() => {
          setBtnLoader({ type: "DOWNLOAD", loading: false });
        });
    } else {
      toast.error("Invalid Inputs");
    }
  };

  const uploadHandler = async (file) => {
    try {
      const accountId = filteredAccountObject?.accounts_id;
      const clientId = filteredAccountObject?.client_id;
      if (selectedProjectId && selectedTemplateId && accountId && clientId) {
        setBtnLoader({ type: "UPLOAD", loading: true });
        let response;
        const formData = new FormData(); // Create FormData object

        // Append other fields
        formData.append("projects_id", selectedProjectId);
        formData.append("client_id", clientId);
        formData.append("account_id", accountId);
        formData.append("templates_id", selectedTemplateId);
        formData.append("file", file);

        response = await axiosInstance.post("/upload-job-excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response?.data?.error === false) {
          toast.success(response?.data?.message);
          fetchData();
        } else {
          toast.error("ERROR:" + response?.data?.message);
          console.error(response?.data?.message);
        }
      } else {
        toast.error("Invalid Inputs");
      }
    } catch (error) {
      console.error("Error creating account:", error);
    } finally {
      setBtnLoader({ type: "UPLOAD", loading: false });
    }
  };
  const fileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      uploadHandler(file);
    }
  };
  const handleUploadInputClick = () => {
    fileInputRef.current.click();
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 36,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    background: "red",
    width: 140,
  });
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ overflowX: "auto", maxWidth: "100%" }}
      >
        <Grid size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              // justifyContent: "center",
              gap: "16px",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "26px",
                lineHeight: "30px",
                marginRight: "16px",
                color: "#333333",
              }}
            >
              Add Jobs
            </Typography>

            <Box sx={{ flexGrow: 1, maxWidth: "1000px" }}>
              <SearchBar
                setLoading={setLoading}
                setRows={setRows}
                loadInitialData={() => {}}
                searchAPI={"searchContractors"}
              />
            </Box>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Box
              sx={{
                // display: "flex",
                // flexDirection: "row",
                // columnGap: 3,
                bgcolor: "#F6F6F6",
                p: 2,
                px: 3,
                borderRadius: "5px",
                flexGrow: 1,
                // justifyContent: "space-between",
              }}
            >
              <Grid container spacing={5} sx={{ gap: 3 }}>
                <Grid size={9} spacing={3} sx={{ flexGrow: 1, gap: 3 }}>
                  <Box>
                    <Grid container spacing={5} sx={{ flexGrow: 1 }}>
                      <Grid size={4}>
                        <FormInputDropdown
                          name="projects_id"
                          control={control}
                          label={"Project Name"}
                          type="single"
                          options={projectList || []}
                          errors={errors}
                          defaultValue={""}
                          mandatory="true"
                        />
                      </Grid>

                      <Grid size={4}>
                        <FormInputDropdown
                          name="templates_id"
                          control={control}
                          label={"Template Name"}
                          type="single"
                          options={templateList || []}
                          errors={errors}
                          defaultValue={""}
                          mandatory="true"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Grid container spacing={5} sx={{ gap: 3 }}>
                      <Grid size={4}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            fontFamily: "var(--font-primary)",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "22.4px",
                            color: "#000000",
                            textWrap: "nowrap",
                          }}
                        >
                          {`Account Name: ${
                            filteredAccountObject?.accountslist
                              ? filteredAccountObject?.accountslist?.[0]
                                  ?.account_name
                              : "-"
                          }`}
                        </Typography>
                      </Grid>

                      <Grid size={4}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            fontFamily: "var(--font-primary)",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "22.4px",
                            color: "#000000",
                            textWrap: "nowrap",
                          }}
                        >
                          {`Client Name: ${
                            filteredAccountObject?.clientslist
                              ? filteredAccountObject?.clientslist?.[0]
                                  ?.client_name
                              : "-"
                          }`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid
                  size={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      width: "140px",
                      height: "36px",
                      padding: "8px 16px",
                      borderRadius: "5px",
                      color: "white",
                      boxShadow: "none",
                      border: "1px solid #EF7540",
                      opacity: 1,
                      backgroundColor: "#EF7540",
                      "&:hover": {
                        backgroundColor: "#d96533",
                      },
                    }}
                    onClick={downloadHandler}
                    startIcon={
                      btnLoader?.type == "DOWNLOAD" && btnLoader?.loading ? (
                        <CircularProgress color="#EF7540" size="small" />
                      ) : (
                        <FileDownloadOutlinedIcon />
                      )
                    }
                  >
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: "140px",
                      height: "36px",
                      padding: "8px 16px",
                      borderRadius: "5px",
                      border: "1px solid #EF7540",
                      boxShadow: "none",
                      opacity: 1,
                      backgroundColor: "#FFFFFF",
                      color: "#EF7540",
                    }}
                    // onClick={uploadHandler}
                    onClick={handleUploadInputClick}
                    startIcon={
                      btnLoader?.type == "UPLOAD" && btnLoader?.loading ? (
                        <CircularProgress color="#EF7540" size="small" />
                      ) : (
                        <FileUploadOutlinedIcon />
                      )
                    }
                  >
                    Upload
                    <VisuallyHiddenInput
                      type="file"
                      ref={fileInputRef}
                      onChange={fileHandler}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <GenerateDataTable
            rows={jobColumns || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.sno}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AddJobs;
