import {
  Box,
  Grid2 as Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";

function StatStatus({ categoryType, setCategoryType, data, statusCount }) {
  return (
    <Box sx={{ mb: 3 }}>
      <Box
        sx={{
          bgcolor: "#F6F6F6",
          p: 2,
          px: 3,
          borderRadius: "5px",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={5} sx={{ gap: 3 }}>
          <Grid item size={10} spacing={3} sx={{ flexGrow: 1, gap: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                justifyContent: "center",
              }}
            >
              <Grid item size={4}>
                <Select
                  value={categoryType}
                  onChange={(e) => setCategoryType(e?.target?.value)}
                  displayEmpty
                  style={{
                    width: "100%",
                    height: "56px",
                    padding: "8px",
                    gap: "12px",
                    borderRadius: "5px",
                    opacity: 1,
                    fontSize: 16,
                    fontWeight: 400,
                    border: "1px solid #EF7540",
                  }}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <MenuItem value={""}>ALL</MenuItem>
                  {data?.map((item) => (
                    <MenuItem value={item?.categories_id}>
                      {item?.categories_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                size={4}
                sx={{
                  height: "56px",
                  padding: "8px",
                  gap: "12px",
                  borderRadius: "5px",
                  border: "1px solid #47A0DE",
                  opacity: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    gap: "0px",
                    opacity: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "22.4px",
                    color: "#000000",
                    width: "100%",
                  }}
                >
                  <span>Open</span>
                  <span>{statusCount?.open}</span>
                </Box>
              </Grid>

              <Grid
                item
                size={4}
                sx={{
                  height: "56px",
                  padding: "8px",
                  gap: "12px",
                  borderRadius: "5px",
                  border: "1px solid #47A0DE",
                  opacity: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    height: "22px",
                    gap: "0px",
                    opacity: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "22.4px",
                    textAlign: "left",
                    color: "#000000",
                    width: "100%",
                  }}
                >
                  <span>Closed</span>
                  <span>{statusCount?.closed}</span>
                </Box>
              </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Grid
                container
                spacing={3}
                justifyContent={"space-evenly"}
                className="status-count-stats"
              >
                <Grid item xs={2}>
                  <Typography textAlign="center">Assigned</Typography>
                  <Typography textAlign="center">
                    {statusCount?.["Assigned"]}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">Submitted</Typography>
                  <Typography textAlign="center">
                    {statusCount?.["Submitted"]}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">Re-assigned</Typography>
                  <Typography textAlign="center">
                    {statusCount?.["Re - Assigned"]}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">Re-submitted</Typography>
                  <Typography textAlign="center">
                    {statusCount?.["Re - Submitted"]}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">N/A Approved</Typography>
                  <Typography textAlign="center">
                    {statusCount?.["N/A - Approved"]}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">Approved</Typography>
                  <Typography textAlign="center">
                    {statusCount?.["Approved"]}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default StatStatus;
