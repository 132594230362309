import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";

const LogoutModal = ({ logoutModal, setLogoutModal }) => {
  const navigate = useNavigate();
  const { logout, setUserData, setToken } = useAuth();
  const [btnLoading, setBtnLoading] = useState(false);
  const logoutHandler = () => {
    sessionStorage.clear();
    logout();
    setUserData(null);
    setToken("");
    setLogoutModal(false);
    navigate("/login");
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={logoutModal}
      onClose={() => setLogoutModal(false)}
      PaperProps={{
        sx: {
          padding: "0px",
          borderRadius: "5px 0 0 0",
          backgroundColor: "#F6F6F6",
          opacity: 1,
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "16px 24px",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "22px",
              fontWeight: 700,
              color: "#272625",
            }}
          >
            Logout
          </Typography>
          <IconButton
            onClick={() => setLogoutModal(false)}
            sx={{
              width: "24px",
              height: "24px",
              border: "1px solid #FF3B30",
            }}
          >
            <CloseIcon sx={{ color: "#FF3B30" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
        <Grid
          container
          columnSpacing={{ xs: 5 }}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid size={12}>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: 500,
                color: "#272625",
              }}
            >
              Are you sure to logout?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          variant="contained"
          onClick={logoutHandler}
          size="small"
        >
          {btnLoading ? (
            <CircularProgress color="#FFFFFF" size="20px" />
          ) : (
            "Logout"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
