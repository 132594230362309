import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "../../../api/axios";
import { ACKNOWLEDGE_SCHEMA } from "../../../utils/schema";
import { FormInputText } from "../../form-components/FormInputText";
import GenerateDataTable from "../../reusable/GenerateDataTable";
import AcknowledgementRecentChanges from "./AcknowledgementRecentChanges";
import SearchBar from "../../reusable/SearchBar";
import { Plus } from "@phosphor-icons/react";
import RecentChangesContainer from "../../reusable/RecentChangesContainer";

function Acknowledgement() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [recentChanges, setRecentChanges] = useState([]);

  const loadInitialData = () => {
    setLoading(true);
    setSelectedRow(null);
    axiosInstance
      .get(`/indexAcknowledgement`)
      .then((response) => {
        if (response.data.error === false) {
          const formattedData = response.data.data?.data?.map(
            (item, index) => ({
              index,
              ...item,
            })
          );
          setRows(formattedData);
          const recentChangesData = response?.data?.data?.recent_changes?.map(
            (item, index) => {
              const label = "Name";
              const action = item?.action;
              const name = item?.acknowledgement?.acknowledgement_name;
              const oldData = JSON.parse(item.old_data);
              const newData = JSON.parse(item.new_data);
              const oldValue = oldData?.acknowledgement_name;
              const newValue = newData?.acknowledgement_name;
              const oldStatus = oldData?.active_status;
              const newStatus = newData?.active_status;
              const createdAt = oldData?.created_at;
              const updatedAt = newData?.updated_at;
              const data = {
                label,
                action,
                name,
                oldValue,
                newValue,
                oldStatus,
                newStatus,
                createdAt,
                updatedAt,
              };
              return data;
            }
          );
          setRecentChanges(recentChangesData);
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => loadInitialData(), []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = async (event, currentRow) => {
    const newStatus = event.target.value;
    if (newStatus == "EDIT") {
      handleOpen();
      setSelectedRow(currentRow);
      return;
    }

    try {
      if (+currentRow?.active_status != +newStatus) {
        const response = await axiosInstance.get(
          `/acknowledgementSwitch/${currentRow?.acknowledgement_id}`,
          {
            status: newStatus,
          }
        );

        if (response.data.error) {
          console.error(response.data.message);
          toast.error(response.data.message);
        } else {
          loadInitialData();
          toast.success(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error updating account status:", error);
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "S.No",
      valueGetter: (row) => row + 1,
    },
    {
      field: "acknowledgement_name",
      headerName: "Acknowledgement Name",
      flex: 1,
    },
    {
      field: "active_status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => (
        <Select
          value={row.active_status}
          onChange={(e) => handleChangeStatus(e, row)}
          displayEmpty
          style={{
            width: "275px",
            height: "40px",
            borderRadius: "5px",
            background: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
            opacity: 1,
            fontSize: 16,
            fontWeight: 400,
            border: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
          }}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="1">Active</MenuItem>
          <MenuItem value="0">Inactive</MenuItem>
          {row.active_status == "0" && <MenuItem value="EDIT">Edit</MenuItem>}
        </Select>
      ),
    },
  ];

  const RecentChangeContainer = () => (
    <Paper className="recent-changes-container">
      <Typography
        variant="h6"
        style={{
          fontWeight: 600,
          fontSize: "16px",
          fontFamily: "Inter",
          lineHeight: "19.36px",
          margin: "0px 0px 8px 0px",
          color: "#000000",
        }}
      >
        Recent Changes
      </Typography>
      <Typography
        variant="body2"
        style={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "16.94px",
          // margin: '0px 0px 16px 0px',
          color: "#636363",
        }}
      >
        View all the recent changes made from your end and keep tabs.
      </Typography>
      <Box sx={{ mt: 4 }}>
        {[1, 2, 3].map((item) => (
          <AcknowledgementRecentChanges item={item} />
        ))}
      </Box>
    </Paper>
  );
  const Modal = () => {
    const [btnLoading, setBtnLoading] = useState(false);
    const methods = useForm({ resolver: yupResolver(ACKNOWLEDGE_SCHEMA) });
    const {
      handleSubmit,
      control,
      formState: { errors },
    } = methods;
    const handleFormSubmit = async (data) => {
      try {
        let response;
        setBtnLoading(true);
        if (selectedRow?.acknowledgement_id) {
          const params = {
            acknowledgement_name: data.acknowledgement_name,
            active_status: selectedRow?.active_status,
            acknowledgement_id: selectedRow?.acknowledgement_id,
          };
          response = await axiosInstance.post("/updateAcknowledgement", params);
        } else {
          const params = {
            acknowledgement_name: data.acknowledgement_name,
            active_status: 0,
          };
          response = await axiosInstance.post("/acknowledgement", params);
        }

        if (response?.data?.error === false) {
          toast.success(response?.data?.message);
          loadInitialData();
          handleClose();
        } else {
          toast.error("ERROR:" + response?.data?.message);
          console.error(response?.data?.message);
        }
      } catch (error) {
        console.error("Error creating account:", error);
      } finally {
        setBtnLoading(true);
      }
    };
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "0px",
            borderRadius: "5px 0 0 0",
            backgroundColor: "#F6F6F6",
            opacity: 1,
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "16px 24px",
              backgroundColor: "#F6F6F6",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "22px",
                fontWeight: 700,
                color: "#272625",
              }}
            >
              {selectedRow?.acknowledgement_id
                ? "Edit Acknowledgement"
                : "Create Acknowledgement"}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                width: "24px",
                height: "24px",
                border: "1px solid #FF3B30",
              }}
            >
              <CloseIcon sx={{ color: "#FF3B30" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
          <Grid
            container
            columnSpacing={{ xs: 5 }}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid size={12}>
              <FormInputText
                name="acknowledgement_name"
                control={control}
                label="Acknowledgement Name"
                errors={errors}
                mandatory="true"
                placeholder={"Enter Acknowledgement Name"}
                defaultValue={selectedRow?.acknowledgement_name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(handleFormSubmit)}
          >
            {btnLoading ? (
              <CircularProgress color="#FFFFFF" size="20px" />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={9}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "26px",
                lineHeight: "30px",
                marginRight: "16px",
                color: "#333333",
              }}
            >
              Acknowledgement
            </Typography>

            <SearchBar
              setLoading={setLoading}
              setRows={setRows}
              loadInitialData={loadInitialData}
              searchAPI={"searchAcknowledgement"}
            />
          </Box>
          <GenerateDataTable
            rows={rows || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.acknowledgement_id}
          />
        </Grid>
        <Grid
          size={3}
          sx={{ gap: "16px", display: "flex", flexDirection: "column" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            sx={{
              height: "40px",
              padding: "8px, 16px, 8px, 16px",
              borderRadius: "8px",
              backgroundColor: "#47A0DE",
              color: "#FFFFFF",
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "24px",
              gap: "10px",
              textTransform: "capitalize",
            }}
          >
            <Plus size={20} weight="bold" />
            New Record
          </Button>
          <RecentChangesContainer data={recentChanges} />
        </Grid>
      </Grid>
      <Modal />
    </>
  );
}

export default Acknowledgement;
