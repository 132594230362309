import React from "react";
import RecentChangesCreateItem from "./RecentChangesCreateItem";
import RecentChangesUpdateItem from "./RecentChangesUpdateItem";
import RecentChangesStatusItem from "./RecentChangesStatusItem";
import { Box, Paper, Typography } from "@mui/material";

const RecentChangesContainer = ({ data }) => {
  return (
    <Paper className="recent-changes-container">
      <Typography
        variant="h6"
        style={{
          fontWeight: 600,
          fontSize: "16px",
          fontFamily: "Inter",
          lineHeight: "19.36px",
          margin: "0px 0px 8px 0px",
          color: "#000000",
        }}
      >
        Recent Changes
      </Typography>
      <Typography
        variant="body2"
        style={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "16.94px",
          // margin: '0px 0px 16px 0px',
          color: "#636363",
        }}
      >
        View all the recent changes made from your end and keep tabs.
      </Typography>
      <Box sx={{ mt: 4 }}>
        {data?.length > 0 ? (
          data?.map((item, index) => {
            switch (item?.action) {
              case "create":
                return (
                  <RecentChangesCreateItem
                    key={index}
                    index={index}
                    item={item}
                  />
                );
              case "update":
                return (
                  <RecentChangesUpdateItem
                    key={index}
                    index={index}
                    item={item}
                  />
                );
              case "status_change":
                return (
                  <RecentChangesStatusItem
                    key={index}
                    index={index}
                    item={item}
                  />
                );
            }
          })
        ) : (
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "12px",
              fontWeight: 600,
              color: "#272625",
              textAlign: "center",
            }}
          >
            No Changes Found !
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default RecentChangesContainer;
