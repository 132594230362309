import React, { useEffect, useState } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";

const ImageMarkup = ({ imageUrl, canvasRef, selectedColor }) => {
  const [base64ImageUrl, setBase64ImageUrl] = useState("");

  const convertToBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  useEffect(() => {
    const loadImage = async () => {
      const base64 = await convertToBase64(imageUrl);
      setBase64ImageUrl(base64);
    };
    loadImage();
  }, [imageUrl]);
  return (
    <ReactSketchCanvas
      ref={canvasRef}
      strokeWidth={4}
      strokeColor={selectedColor}
      style={{ width: "100%", height: "300px" }}
      backgroundImage={base64ImageUrl}
      preserveBackgroundImageAspectRatio={"xMidYMin"}
    />
  );
};

export default ImageMarkup;
