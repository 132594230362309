import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import { Avatar, Collapse } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import BreadcrumbsComponent from "./Breadcrumbs";
import LogoutModal from "./LogoutModal";
import { useAuth } from "../../context/AuthContext";
import { capitalizeFirstLetter } from "../screens/add_jobs/AddJobs";

const drawerWidth = 266;

function Layout(props) {
  const { userData } = useAuth();

  const mainMenu = [
    {
      key: "config",
      text: "Config",
      icon: SettingsOutlinedIcon,
      subMenu: true,
      open: false,
      items: [
        {
          key: "accounts",
          text: "Accounts",
          icon: AccountCircleOutlinedIcon,
          route: "/accounts",
        },
        {
          key: "clients",
          text: "Clients",
          icon: Diversity1OutlinedIcon,
          route: "/clients",
        },
        {
          key: "contractors",
          text: "Contractors",
          icon: DescriptionOutlinedIcon,
          route: "/contractors",
        },
        {
          key: "categories",
          text: "Categories",
          icon: CategoryOutlinedIcon,
          route: "/categories",
        },
        {
          key: "status",
          text: "Status",
          icon: QueryStatsOutlinedIcon,
          route: "/status",
        },
        {
          key: "roles",
          text: "Roles",
          icon: SupervisorAccountOutlinedIcon,
          route: "/roles",
        },
        {
          key: "acknowledgement",
          text: "Acknowledgement",
          icon: HandshakeOutlinedIcon,
          route: "/acknowledgement",
        },
        {
          key: "projects",
          text: "Projects",
          icon: InventoryOutlinedIcon,
          route: "/projects",
        },
        {
          key: "attachment-type",
          text: "Attachment Type",
          icon: AttachFileOutlinedIcon,
          route: "/attachment-type",
        },
      ],
    },
    {
      key: "users",
      text: "Users",
      icon: SupervisorAccountOutlinedIcon,
      subMenu: true,
      open: false,
      items: [
        {
          key: "employees",
          text: "Employees",
          icon: AccountCircleOutlinedIcon,
          route: "/employees-details",
        },
        {
          key: "contractors",
          text: "Contractors",
          icon: AccountCircleOutlinedIcon,
          route: "/contractors-details",
        },
        {
          key: "clients",
          text: "Clients",
          icon: Diversity1OutlinedIcon,
          route: "/clients-details",
        },
      ],
    },
    {
      key: "add-jobs",
      text: "Add Jobs",
      icon: DescriptionOutlinedIcon,
      subMenu: false,
      route: "/add-jobs",
    },
  ];
  const simplifiedMainMenu = [
    {
      key: "jobs",
      text: "Jobs",
      icon: DescriptionOutlinedIcon,
      subMenu: false,
      route: "/jobs/" + userData?.ueser_create_id,
    },
  ];
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // To get the current route
  const currentRoute = location.pathname;
  const [open, setOpen] = React.useState(true);
  const [menuItems, setMenuItems] = React.useState(mainMenu);
  const [logoutModal, setLogoutModal] = React.useState(false);

  const finalMenu = userData?.admin_user ? menuItems : simplifiedMainMenu;
  const fullName = `${userData?.users_ids?.[0]?.first_name} ${userData?.users_ids?.[0]?.last_name}`;

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const handleMenuClick = (index) => {
    const updatedMenuItems = finalMenu.map((item, i) => {
      if (i === index) {
        return { ...item, open: !item.open };
      } else {
        return { ...item, open: false };
      }
    });
    setMenuItems(updatedMenuItems);
  };

  const SearchInput = () => {
    return (
      <div className="search-container">
        <SearchIcon className="search-icon" />
        <input type="text" placeholder="Search..." className="search-input" />
      </div>
    );
  };
  const DrawerHeaderWithLogo = () => {
    return (
      <>
        <div className="side-nav-header">
          <Avatar
            src={require("../../assets/logo.png")}
            alt="Logo"
            className="logo"
          />
          <div className="text-container">
            <div className="name">{capitalizeFirstLetter(fullName)}</div>
            <div className="email">{userData?.user_email}</div>
          </div>
        </div>
        {/* <SearchInput /> */}
      </>
    );
  };
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
    flexDirection: "column",
  }));
  useEffect(() => {
    console.log(currentRoute);
    if (!currentRoute) return;

    const mainMenuFromRoute = currentRoute.split("/")?.[1];
    const filteredMainMenuIndex = finalMenu?.findIndex(
      (item) => item?.key === mainMenuFromRoute && !item?.open
    );

    if (filteredMainMenuIndex >= 0) {
      handleMenuClick(filteredMainMenuIndex);
    }
  }, [currentRoute]);

  const drawer = (
    <div className="drawer-container">
      <div>
        <DrawerHeader>
          <DrawerHeaderWithLogo />
        </DrawerHeader>
        <div style={{ gap: 18, display: "flex", flexDirection: "column" }}>
          <List className="side-nav-bar-menu">
            <SearchInput />
            {finalMenu.map((item, index) => (
              <React.Fragment key={item.key}>
                {!item.subMenu ? (
                  <ListItem
                    button
                    onClick={() => navigate(item?.route)}
                    className="main-list-item"
                    sx={{
                      backgroundColor:
                        currentRoute === item?.route
                          ? "#47A0DE !important"
                          : "transparent",
                      color: currentRoute === item?.route && "white !important",
                    }}
                  >
                    <ListItemIcon>
                      <item.icon
                        sx={{
                          color:
                            currentRoute === item?.route
                              ? "#FFFFFF"
                              : " #09090A",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={item?.text}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color:
                            currentRoute === item?.route
                              ? "#FFFFFF"
                              : "#09090A",
                        },
                      }}
                    />
                  </ListItem>
                ) : (
                  <>
                    <ListItemButton
                      onClick={() => handleMenuClick(index)}
                      sx={{
                        border: item.open ? "2px solid #47A0DE" : "none",
                      }}
                    >
                      <ListItemIcon>
                        {item.open ? (
                          <ExpandMoreIcon />
                        ) : (
                          <item.icon sx={{ color: "grey" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={`${item.text}`} />
                    </ListItemButton>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.items.map((subItem) => (
                          <ListItem
                            sx={{
                              px: open ? "32px" : "16px",
                              bgcolor:
                                currentRoute ===
                                `/${item?.key}${subItem?.route}`
                                  ? "#47A0DE !important"
                                  : "transparent",
                            }}
                            key={subItem.key}
                            button
                            onClick={() =>
                              navigate(`${item?.key}${subItem?.route}`)
                            }
                          >
                            <ListItemIcon>
                              <subItem.icon
                                sx={{
                                  ml: 2,
                                  color:
                                    currentRoute ===
                                    `/${item?.key}${subItem?.route}`
                                      ? "#FFFFFF"
                                      : " #09090A",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={subItem.text}
                              primaryTypographyProps={{
                                sx: {
                                  color:
                                    currentRoute ===
                                    `/${item?.key}${subItem?.route}`
                                      ? "#FFFFFF !important"
                                      : " #09090A !important",
                                },
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}
              </React.Fragment>
            ))}
          </List>
        </div>
      </div>

      <List>
        {["Logout"].map((text, index) => (
          <>
            <Divider />
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => setLogoutModal(true)}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={text} sx={{ color: "#FF3B30" }} />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} className="sidebar-container">
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "white",
        }}
      >
        <Toolbar className="toolbar-container">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <BreadcrumbsComponent />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              padding: "24px",
              justifyContent: "space-between",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
        <LogoutModal
          logoutModal={logoutModal}
          setLogoutModal={setLogoutModal}
        />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
