import { Box, MenuItem, Select } from "@mui/material";
import React from "react";

function StatusDropDown({ row, handleChangeStatus, styleProps, edit = true }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Select
        value={row.active_status}
        onChange={(e) => handleChangeStatus(e, row)}
        displayEmpty
        style={{
          minWidth: "200px",
          maxWidth: "300px",
          height: "40px",
          borderRadius: "5px",
          background: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
          opacity: 1,
          fontSize: 16,
          fontWeight: 400,
          border: row.active_status == "1" ? "#D3F3D4" : "#F9B5B5",
          ...styleProps,
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        <MenuItem value="1">Active</MenuItem>
        <MenuItem value="0">Inactive</MenuItem>
        {row.active_status == "0" && edit && (
          <MenuItem value="EDIT">Edit</MenuItem>
        )}
      </Select>

      {/* <VisibilityOutlined
        sx={{ cursor: "pointer", color: "#000000", fontSize: "24px" }}
        onClick={() => handleViewbutton(row)}
      /> */}
    </Box>
  );
}

export default StatusDropDown;
