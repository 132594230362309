// import { DatePicker } from "@mui/x-date-pickers";
import {
  alpha,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  styled,
  Typography
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

export const FormInputDate = ({
  name,
  control,
  label,
  errors,
  type,
  defaultValue,
  mandatory,
  isViewMode = false,
}) => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    width: "100%",
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#E0E3E7",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      ...theme.applyStyles("dark", {
        backgroundColor: "#1A2027",
        borderColor: "#2D3843",
      }),
    },
  }));
  return (
    <Controller
      name={name}
      defaultValue={defaultValue ? new Date(defaultValue) : null}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <FormControl variant="standard" className="custom-form-control">
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                className="text-input-label"
              >
                {label}
              </InputLabel>
              {isViewMode ? (
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontFamily: "var(--font-primary)",
                    fontWeight: "400",
                    fontSize: "16px",
                    // lineHeight: "22.4",
                    color: "#767676",
                    textWrap: "nowrap",
                    marginTop: "20px",
                  }}
                >
                  {defaultValue}
                </Typography>
              ) : (
                <>
                  <BootstrapInput
                    defaultValue={defaultValue || value}
                    id="bootstrap-input"
                    fullWidth
                    // size="small"
                    type={type || "text"}
                    variant="outlined"
                    onChange={onChange}
                    error={error?.message ? true : false}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {error?.message || ""}
                  </FormHelperText>
                </>
              )}
            </FormControl>
          </>
        );
      }}
    />
  );
};
