import Mail from "@mui/icons-material/Mail";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputBase,
  InputLabel,
  styled,
  Typography,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import whiteImage from "../../../assets/White.png";
import axiosInstance from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { LOGIN_SCHEMA } from "../../../utils/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../context/AuthContext";

const Login = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const { login, setToken, setUserData } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: "",
      password: "",
      stayLoggedIn: false,
    },
    resolver: yupResolver(LOGIN_SCHEMA),
  });

  // Handle form submission
  const handleLogin = async (data) => {
    const { username, password } = data;

    try {
      setBtnLoader(true);
      const payload = {
        user_name: username,
        password: password,
      };
      const response = await axiosInstance.post("/loginUser", payload);
      if (!response?.data?.error) {
        const token = response.data?.data?.token;
        sessionStorage.setItem("token", token);
        setToken(token);
        const ADMIN_ACCESS = [9, 10];
        let isAdminUser = ADMIN_ACCESS?.findIndex(
          (item) => item == response.data?.data?.role_id
        );
        isAdminUser = isAdminUser != -1 ? true : false;
        const storeData = { ...response.data?.data, admin_user: isAdminUser };
        setUserData(storeData);
        login(storeData);
        if (isAdminUser) {
          navigate("/config/accounts");
        } else {
          navigate("/jobs/" + response.data?.data?.ueser_create_id);
        }
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Invalid username or password");
    } finally {
      setBtnLoader(false);
    }
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#F3F6F9",
      border: "1px solid",
      borderColor: "#CDE3E4",
      fontSize: 16,
      lineHeight: 22.4,
      fontWeight: 400,
      width: "100%",
      padding: "10px 12px",
      fontFamily: "var(--font-alternative)",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: ["var(--font-alternative)"].join(","),
      "&::placeholder": {
        color: "#819FA1",
        opacity: 1,
      },
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      ...theme.applyStyles("dark", {
        backgroundColor: "#1A2027",
        borderColor: "#2D3843",
      }),
    },
  }));
  return (
    <Grid container className="login-container">
      <Grid
        size={{ xs: 12, md: 6 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="img"
          src={whiteImage}
          alt="White Logo"
          sx={{ m: 5, width: "55%" }}
        />
      </Grid>
      <Grid
        size={{ xs: 12, md: 6 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <Box className="login-form-container">
          <Box
            component="form"
            onSubmit={handleSubmit(handleLogin)}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box sx={{ gap: 6 }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "var(--font-alternative)",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#50A5E0",
                  marginBottom: 0,
                  lineHeight: "24px",
                  textAlign: "left",
                }}
              >
                Login
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "var(--font-alternative)",
                  fontSize: "33px",
                  fontWeight: 600,
                  color: "#1A2122",
                  marginBottom: 2,
                  lineHeight: "45px",
                  textAlign: "left",
                  mb: 0,
                }}
              >
                Welcome to Wizzio
              </Typography>
            </Box>

            <Controller
              name="username"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <FormControl
                    variant="standard"
                    className="custom-form-control"
                  >
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className="text-input-label"
                    >
                      Username
                    </InputLabel>
                    <BootstrapInput
                      defaultValue={value}
                      id="bootstrap-input"
                      fullWidth
                      // size="small"
                      helperText={error?.message}
                      type={"text"}
                      placeholder={"Enter your username"}
                      variant="outlined"
                      onChange={onChange}
                      error={true}
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Mail />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText style={{ color: "red" }}>
                      {error?.message || ""}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              name="password"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <FormControl
                    variant="standard"
                    className="custom-form-control"
                  >
                    <InputLabel
                      shrink
                      htmlFor="bootstrap-input"
                      className="text-input-label"
                    >
                      Password
                    </InputLabel>
                    <BootstrapInput
                      defaultValue={value}
                      id="bootstrap-input"
                      fullWidth
                      type="password"
                      // size="small"
                      // helperText={errors?.[name]?.message}
                      placeholder={"Enter your password"}
                      variant="outlined"
                      onChange={onChange}
                      error={error?.message ? true : false}
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Mail />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText style={{ color: "red" }}>
                      {error?.message || ""}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              name="stayLoggedIn"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ color: "red" }}
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                          border: "1px solid #CDE3E4",
                          borderRadius: "4px",
                        },
                        "& .MuiSvgIcon-root path": {
                          display: "none",
                        },
                        "&.Mui-checked .MuiSvgIcon-root path": {
                          display: "block",
                        },
                        "&.Mui-checked .MuiSvgIcon-root": {
                          borderColor: "#3B82F6",
                        },
                      }}
                    />
                  }
                  className="text-input-label"
                  label="Stay Logged In"
                />
              )}
            />
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  marginTop: { xs: "0px", sm: "48px" },
                  width: "100%",
                  height: "48px",
                  borderRadius: "8px",
                  justifyContent: "center",
                  padding: "0px 16px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  backgroundColor: "#47A0DE",
                  "&:hover": {
                    backgroundColor: "#47A0DE",
                  },
                }}
              >
                {btnLoader ? (
                  <CircularProgress color="#FFFFFF" size="24px" />
                ) : (
                  "Log in"
                )}
              </Button>
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 300,
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#64748B",
                  mt: 1,
                }}
              >
                If you are facing any issues, contact{" "}
                <a
                  href="#"
                  style={{
                    color: "#089BA6",
                    textDecoration: "none",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                >
                  Support
                </a>
              </Box>
            </div>
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "21.82px",
            textAlign: "center",
            color: "white",
            my: 2,
            fontFamily: "var(--font-alternative)",
          }}
        >
          Copyright © Wizzio 2024 | All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
