import { yupResolver } from "@hookform/resolvers/yup";
import {
  KeyboardArrowLeftOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ArrowLeft, Plus } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axiosInstance from "../../../../api/axios";
import {
  CLIENT_SCHEMA,
  USER_CLIENT_PROJECT_ALLOCATION_SCHEMA,
  USER_CLIENT_SCHEMA,
} from "../../../../utils/schema";
import { FormInputText } from "../../../form-components/FormInputText";
import GenerateDataTable from "../../../reusable/GenerateDataTable";
import SearchBar from "../../../reusable/SearchBar";
import StatusDropDown from "../../../reusable/StatusDropDown";
import ThreeDotMenu from "../../../reusable/ThreeDotMenu";
import { FormInputDropdown } from "../../../form-components/FormInputDropdown";
import ImageUpload from "../../../form-components/ImageUpload";
import { FormInputDate } from "../../../form-components/FormInputDate";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSummaryItem from "../../../reusable/HeaderSummaryItem";

export function ContractorProjectAllocation() {
  const navigate = useNavigate();
  const { user_create_id } = useParams();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isViewMode, setIsViewMode] = useState(true);
  const [dropDownData, setDropDownData] = useState({});
  const [allocations, setAllocations] = useState([]);

  const loadInitialData = async () => {
    try {
      setLoading(true);
      setSelectedRow(null);
      const [
        userCreateResponse,
        projectAllocationResponse,
        contractorResponse,
      ] = await Promise.all([
        axiosInstance.get(`/getUsersCreateDetailsId/${user_create_id}`),
        axiosInstance.get(`/indexProjectAllocationClient/${user_create_id}`),
        axiosInstance.get(`/getContractorDetailsId/${user_create_id}`),
      ]);
      if (userCreateResponse?.data?.data) {
        setRows(userCreateResponse?.data?.data);
      }

      if (projectAllocationResponse?.data?.data?.length > 0) {
        const indexedArray = projectAllocationResponse.data.data.map(
          (item, index) => ({
            index,
            ...item,
          })
        );
        setAllocations(indexedArray);
      }
      if (contractorResponse?.data?.data) {
        setDropDownData((prev) => ({
          ...prev,
          activeClients: contractorResponse?.data?.data?.client_data,
        }));
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  const loadDropDownData = async () => {
    try {
      setLoading(true);
      setSelectedRow(null);

      const [projectResponse, rolesResponse] = await Promise.all([
        axiosInstance.get("/getActiveAllProjects"),
        axiosInstance.get("/getActiveAllroles"),
      ]);

      if (projectResponse?.data?.data?.length > 0) {
        const formattedProjectsData = projectResponse?.data?.data.map(
          (item, index) => ({
            index,
            ...item,
          })
        );
        setDropDownData((prev) => ({
          ...prev,
          activeProjects: formattedProjectsData,
        }));
      }

      if (rolesResponse?.data?.data?.length > 0) {
        setDropDownData((prev) => ({
          ...prev,
          roleList: rolesResponse?.data?.data,
        }));
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleNewRecord = () => {
    setSelectedRow(null);
    setUserDetails(null);
    setIsViewMode(false);
    setOpen(true);
  };

  useEffect(() => {
    loadInitialData();
    loadDropDownData();
  }, []);

  const handleChangeStatus = async (event, currentRow) => {
    const newStatus = event.target.value;
    if (newStatus == "EDIT") {
      handleOpen();
      setSelectedRow(currentRow);
      return;
    }

    try {
      if (+currentRow?.active_status != +newStatus) {
        const response = await axiosInstance.get(
          `/projectAllocationSwitch/${currentRow?.project_allocation_id}`,
          {
            status: newStatus,
          }
        );

        if (response.data.error) {
          console.error(response.data.message);
          toast.error(response.data.message);
        } else {
          loadInitialData();
          toast.success(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error updating account status:", error);
    }
  };
  const viewHandler = (rowData) => {
    setSelectedRow(rowData);
    setOpen(true);
  };

  const columns = [
    {
      field: "index",
      headerName: "S.No",
      flex: 0.5,
      valueGetter: (row) => row + 1,
    },
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 0.75,
      valueGetter: (value, row) => {
        return `${row?.clientlist?.[0]?.client_name}`;
      },
    },
    {
      field: "accounts_id",
      headerName: "User Id",
      flex: 0.75,
      valueGetter: (value, row) => {
        return `${row?.accountlist?.[0]?.account_name}`;
      },
    },
    {
      field: "projects_id",
      headerName: "Project Name",
      flex: 1,
      valueGetter: (value, row) => {
        return `${row?.project_list?.[0]?.project_name}`;
      },
    },
    {
      field: "role_id",
      headerName: "Roles Name",
      flex: 1,
      valueGetter: (value, row) => {
        return `${row?.roles_list?.[0]?.role_name}`;
      },
    },
    {
      field: "active_status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => (
        <StatusDropDown
          row={row}
          handleChangeStatus={handleChangeStatus}
          styleProps={{ minWidth: "150px", maxWidth: "200px" }}
          edit={false}
        />
      ),
    },
  ];

  const Modal = () => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(true);

    const methods = useForm({
      resolver: yupResolver(USER_CLIENT_PROJECT_ALLOCATION_SCHEMA),
    });
    const {
      handleSubmit,
      reset,
      control,
      watch,
      setValue,
      formState: { errors },
    } = methods;
    const toggleEditMode = () => {
      setIsViewMode(false);
    };
    const handleClose = () => {
      setOpen(false);
      setIsViewMode(false);
      setUserDetails(null);
    };

    const handleFormSubmit = async (data) => {
      try {
        setBtnLoading(true);
        let response;
        const params = {
          ...data,
          title: "Mr",
          active_status: 0,
          // id: user_create_id,
          project_allocation_name: rows?.first_name,
          ueser_create_id: user_create_id,
        };
        response = await axiosInstance.post("/projectAllocation", params);

        if (response?.data?.error === false) {
          toast.success(response?.data?.message);
          handleClose();
          loadInitialData();
        } else {
          toast.error("ERROR:" + response?.data?.message);
          console.error(response?.data?.message);
        }
      } catch (error) {
        console.error("Error creating account:", error);
      } finally {
        setBtnLoading(false);
      }
    };

    useEffect(() => {
      if (isViewMode) {
        reset();
      }
      if (isFirstTime && selectedRow?.ueser_create_id) {
        setIsViewMode(true);
        setIsFirstTime(false);
      }
    }, [isViewMode, reset, selectedRow]);

    const selectedContractorId = watch("contractors_id");
    const selectedProjectId = watch("projects_id");
    let projectList = dropDownData?.activeProjects?.filter(
      (item) => item?.contractors_id == selectedContractorId
    );
    // console.log(projectList);
    projectList = projectList?.map((item) => ({
      id: item?.projects_id,
      name: item?.project_name,
    }));

    let filteredAccountList = dropDownData?.activeProjects?.find(
      (item) => item?.projects_id == selectedProjectId
    );

    filteredAccountList = filteredAccountList?.accountslist?.map((item) => ({
      id: item?.accounts_id,
      name: item?.account_name,
    }));
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "0px",
            borderRadius: "5px 0 0 0",
            backgroundColor: "#F6F6F6",
            opacity: 1,
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "16px 24px",
              backgroundColor: "#F6F6F6",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "22px",
                fontWeight: 700,
                color: "#272625",
              }}
            >
              {selectedRow
                ? isViewMode
                  ? "View Client"
                  : "Edit Client"
                : "Adding Client Details"}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                width: "24px",
                height: "24px",
                border: "1px solid #FF3B30",
              }}
            >
              <CloseIcon sx={{ color: "#FF3B30" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
          <Grid container spacing={{ xs: 5 }} sx={{ gap: 3 }}>
            <Grid size={6}>
              <FormInputDropdown
                name="client_id"
                control={control}
                label={"Client Name"}
                type="single"
                options={dropDownData?.activeClients?.map((item) => ({
                  id: item?.client_id,
                  name: item?.client_name,
                }))}
                errors={errors}
                defaultValue={""}
                mandatory="true"
                isViewMode={isViewMode}
              />
            </Grid>
            <Grid size={6}>
              <FormInputDropdown
                name="projects_id"
                control={control}
                label={"Projects"}
                type="single"
                options={projectList || []}
                errors={errors}
                defaultValue={rows?.projects_id || ""}
                mandatory="true"
                isViewMode={isViewMode}
              />
            </Grid>
            <Grid size={6}>
              <FormInputDropdown
                name="accounts_id"
                control={control}
                label={"Account"}
                type="single"
                options={filteredAccountList || []}
                errors={errors}
                defaultValue={selectedRow?.accounts_id || ""}
                mandatory="true"
                isViewMode={isViewMode}
              />
            </Grid>

            <Grid size={6}>
              <FormInputDropdown
                name="role_id"
                control={control}
                label={"Roles"}
                type="single"
                options={dropDownData?.roleList?.map((item) => ({
                  id: item?.role_id,
                  name: item?.role_name,
                }))}
                errors={errors}
                defaultValue={selectedRow?.role_id || ""}
                mandatory="true"
                isViewMode={isViewMode}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={!isValid}
            color="primary"
            variant="contained"
            onClick={handleSubmit(handleFormSubmit)}
            disabled={btnLoading}
            sx={{ px: 2 }}
          >
            {btnLoading ? (
              <CircularProgress color="#FFFFFF" size="20px" />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "26px",
                lineHeight: "30px",
                marginRight: "16px",
                color: "#333333",
                textWrap: "nowrap",
              }}
            >
              <IconButton aria-label="arrow" onClick={() => navigate(-1)}>
                <ArrowLeft size={30} />
              </IconButton>
              Project Allocation
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={handleNewRecord}
              sx={{
                height: "40px",
                padding: "8px, 16px, 8px, 16px",
                borderRadius: "8px",
                backgroundColor: "#47A0DE",
                color: "#FFFFFF",
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "24px",
                gap: "10px",
                textTransform: "capitalize",
                width: "25%",
              }}
            >
              <Plus size={20} weight="bold" />
              New Record
            </Button>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: 3,
                bgcolor: "#F6F6F6",
                pt: 1,
                px: 2,
                borderRadius: "5px",
                justifyContent: "space-between",
              }}
            >
              <HeaderSummaryItem
                heading={"Contractor Name"}
                value={rows?.contractorlist?.[0]?.contract_name}
              />
              <HeaderSummaryItem
                heading={"Mobile No."}
                value={rows?.mobile_no}
              />
              <HeaderSummaryItem heading={"User Id"} value={rows?.user_id} />
              <HeaderSummaryItem heading={"Password"} value={rows?.password} />
              <HeaderSummaryItem heading={"End Date"} value={rows?.end_date} />
            </Box>
          </Box>
          <GenerateDataTable
            rows={allocations || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.project_allocation_id}
          />
        </Grid>
      </Grid>
      <Modal />
    </>
  );
}
