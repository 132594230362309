import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  Grid2 as Grid,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "../../../../api/axios";
import { useAuth } from "../../../../context/AuthContext";

const CommentSection = ({ data }) => {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState(data?.chat_details);
  const [newComment, setNewComment] = useState("");
  const [showAllComments, setShowAllComments] = useState(false);

  const handlePostComment = async () => {
    if (!newComment.trim()) {
      toast.error("Comment cannot be empty");
      return;
    }

    try {
      const payload = {
        chat_description: newComment,
        template_check_list_id: data?.template_check_list_id,
        ueser_create_id: data?.submiter_name_list?.[0]?.ueser_create_id,
      };
      setLoading(true);
      const response = await axiosInstance.post("/chatStored", payload);
      if (!response?.data?.error) {
        const newCommentData = {
          author: userData?.users_ids?.[0]?.first_name || "Unknown",
          content: newComment,
        };

        toast.success("Comment posted!");
        setComments([...comments, newCommentData]);
        setNewComment("");
      }
    } catch (error) {
      toast.error("Failed to post comment");
    } finally {
      setLoading(false);
    }
  };
  const commentsToShow = showAllComments ? comments : comments?.slice(0, 3);

  return (
    <Grid container spacing={3} sx={{ mx: 2 }} className="chat-history">
      <Grid
        size={12}
        sx={{ display: "flex", gap: "16px", flexDirection: "column" }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "19.6px",
            fontFamily: "var(--font-secondary)",
          }}
        >
          Comments ({comments?.length})
        </Typography>

        <TextField
          placeholder="Write a comment"
          fullWidth
          variant="outlined"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !loading) {
              handlePostComment();
            }
          }}
          InputProps={{
            sx: {
              height: "45px",
            },
            endAdornment: loading ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : null,
          }}
        />

        {commentsToShow?.map((comment, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "grey",
                marginRight: "10px",
                width: "33px",
                height: "33px",
              }}
            >
              {comment.user_details?.[0]?.first_name[0].toUpperCase() ||
                comment.author?.[0].toUpperCase()}
            </Avatar>
            <Box sx={{ gap: "3px" }}>
              <Typography
                variant="h6"
                sx={{
                  color: "#09090A",
                  fontWeight: 500,
                  fontSize: "10px",
                  lineHeight: "14px",
                  fontFamily: "var(--font-secondary)",
                }}
              >
                {comment.user_details?.[0]?.first_name || comment.author}
              </Typography>
              <Typography
                sx={{
                  color: "#636363",
                  fontWeight: 400,
                  fontSize: "9px",
                  lineHeight: "12.5px",
                  fontFamily: "var(--font-secondary)",
                }}
              >
                {comment.chat_description || comment.content}
              </Typography>
            </Box>
          </Box>
        ))}

        {comments?.length > 3 && (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              color: "#09090A",
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "14px",
              fontFamily: "var(--font-secondary)",
            }}
            onClick={() => setShowAllComments((prev) => !prev)}
          >
            {showAllComments ? "Show less" : "View all comments"}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CommentSection;
