import axios from "axios";

export const BASE_URL = "https://wizzio-api.wegivethebest.com/";
// export const BASE_URL = "http://localhost:8000/";
const SUFFIX = "api";
// const BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL + SUFFIX,
  // withCredentials: true,
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
