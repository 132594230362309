import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
  alpha,
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  InputBase,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  styled,
} from "@mui/material";
import { theme } from "../../utils/theme";

export const FormInputDropdown = ({
  name,
  control,
  label,
  type,
  placeholder,
  defaultValue,
  maxLength,
  className,
  disabled,
  options,
  isViewMode = false,
}) => {
  const generateSingleOptions = () => {
    return options?.map((option) => {
      return (
        <MenuItem key={option?.id} value={option?.id}>
          {option?.name}
        </MenuItem>
      );
    });
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              className="text-input-label"
            >
              {label}
            </InputLabel>
            {/* <FormControl className="custom-form-control"> */}
            {isViewMode ? (
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontFamily: "var(--font-primary)",
                  fontWeight: "400",
                  fontSize: "16px",
                  // lineHeight: "22.4",
                  color: "#767676",
                  textWrap: "nowrap",
                }}
              >
                {options?.find((item) => item?.id == defaultValue)?.name}
              </Typography>
            ) : (
              <>
                <Select
                  value={value || defaultValue}
                  onChange={onChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    border: theme.colors.inputs,
                    width: "100%",
                    bgcolor: "#FFFFFF",
                    height: 45,
                    "&:hover": {
                      borderColor: theme.colors.inputFocus,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.colors.inputFocus,
                    },
                  }}
                >
                  <MenuItem value={""}>--select--</MenuItem>
                  {generateSingleOptions()}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {error?.message || ""}
                </FormHelperText>
              </>
            )}

            {/* </FormControl> */}
          </>
        );
      }}
    />
  );
};
