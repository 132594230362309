import { styled } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid2 as Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  DialogTitle,
} from "@mui/material";
import axiosInstance, { BASE_URL } from "../../../../api/axios";
import toast from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import CommentSection from "./CommentSection";
import RenderFileViewer from "./RenderFileViewer";
import PreviewControls from "./PreviewControls";
import MarkupEditor from "./MarkupEditor";
import { useAuth } from "../../../../context/AuthContext";

const JobUploadModal = ({
  open,
  setOpen,
  data,
  loadInitialData,
  jobDetailsData,
}) => {
  const {
    userData: { role_id },
  } = useAuth();
  const [btnLoading, setBtnLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [markup, setMarkup] = useState(false);

  // const handleFileUpload = (event) => {
  //   const uploadedFiles = Array.from(event.target.files);
  //   let fileUrl = [];
  //   let fileInfo = [];
  //   uploadedFiles.forEach((file, index) => {
  //     const url = URL.createObjectURL(file);
  //     const extension = file.name.split(".").pop().toLowerCase();
  //     fileUrl.push(url);
  //     fileInfo.push({ url, extension, index });
  //   });
  //   if (markup) {
  //     const selectedImage = files?.find(
  //       (item) => item?.index == selectedFile?.index
  //     );
  //     console.log(selectedImage);
  //   }
  //   setFiles((prev) => [...prev, ...fileInfo]);
  //   setSelectedFile(fileInfo[0]);
  //   uploadImage(uploadedFiles[0]);
  // };
  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    let fileUrl = [];
    let fileInfo = [];

    uploadedFiles.forEach((file, index) => {
      const url = URL.createObjectURL(file);
      const extension = file.name.split(".").pop().toLowerCase();
      fileUrl.push(url);
      fileInfo.push({ url, extension, index });
    });

    if (role_id == 5) {
      const selectedImageIndex = files.findIndex(
        (item) => item.index === selectedFile.index
      );
      if (selectedImageIndex !== -1) {
        const updatedFiles = [...files];
        updatedFiles[selectedImageIndex] = fileInfo[0];
        setFiles(updatedFiles);
      }
    } else {
      setFiles((prev) => [...prev, ...fileInfo]);
    }

    setSelectedFile(fileInfo[0]);
    uploadImage(uploadedFiles[0]);
  };

  const handleFilePreview = (file) => {
    console.log(file);
    setSelectedFile(file);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const uploadImage = (uploadedFile) => {
    if (data?.template_check_list_id && uploadedFile) {
      setBtnLoading(true);
      const formData = new FormData();
      formData.append("re_assigned_images", uploadedFile);
      formData.append("re_assigned_description", null);
      formData.append("status_id", data?.status_id);
      formData.append("hop_image_upload_id", selectedFile?.hop_image_upload_id);
      formData.append("template_check_list_id", data?.template_check_list_id);
      axiosInstance
        .post("/reassignedImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          loadInitialData();
          toast.success(response?.data?.message);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message || error?.message);
        })
        .finally(() => setBtnLoading(false));
    }
  };

  useEffect(() => {
    if (data?.submiterimage?.length > 0) {
      const imageList = data?.submiterimage?.map((item, index) => {
        const imageUrl =
          item?.re_assigned_images?.[0]?.re_assigned_images || item?.hop_image;
        return {
          url: imageUrl,
          extension: imageUrl?.split(".").pop().toLowerCase(),
          index,
          hop_image_upload_id: item?.hop_image_upload_id,
        };
      });
      setFiles(imageList);
      setSelectedFile(imageList[0]);
    } else {
      setFiles([]);
      setSelectedFile(null);
    }
  }, [data]);

  const selectedImageStyles = {
    background: blue[600],
    borderRadius: "50%",
    color: "white",
  };
  const RenderFileItem = ({ file }) => {
    const { url, extension } = file;
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <Box
            component="img"
            src={url}
            alt="Selected File"
            sx={{
              width: "100%",
            }}
          />
        );

      case "pdf":
        return (
          <Box
            component="img"
            src={require("../../../../assets/icons/pdf-file.png")}
            alt="pdf"
            sx={{
              width: "100%",
            }}
          />
        );

      case "xlsx":
      case "xls":
        return (
          <Box
            component="img"
            src={require("../../../../assets/icons/office365.png")}
            alt="pdf"
            sx={{
              width: "100%",
            }}
          />
        );

      default:
        return <p>Unsupported file format</p>;
    }
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          padding: "0px",
          borderRadius: "5px 0 0 0",
          backgroundColor: "#F6F6F6",
          opacity: 1,
          height: "100%",
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", margin: "0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "16px 24px",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "22px",
              fontWeight: 700,
              color: "#272625",
            }}
          >
            HOP Review - Task Name
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              width: "24px",
              height: "24px",
              border: "1px solid #FF3B30",
            }}
          >
            <CloseIcon sx={{ color: "#FF3B30" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px 24px", margin: "0px" }}>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid size={8}>
            <Box>
              <Grid container spacing={3}>
                <Grid size={8}>
                  <Box>
                    <Grid
                      container
                      spacing={3}
                      sx={{ marginTop: "20px", alignItems: "baseline" }}
                    >
                      {files?.map((file, index) => (
                        <Grid
                          size={3}
                          onClick={() => handleFilePreview(file)}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            flexDirection: "column",
                            gap: "15px",
                          }}
                        >
                          <RenderFileItem file={file} />
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 700,
                              lineHeight: "24px",
                              padding: "3px 10px",
                              textAlign: "center",
                              ...(index === selectedFile?.index &&
                                selectedImageStyles),
                            }}
                          >
                            {index + 1}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid size={4}>
                  <PreviewControls
                    selectedFile={selectedFile}
                    setMarkup={setMarkup}
                    handleFileUpload={handleFileUpload}
                    markup={markup}
                    btnLoading={btnLoading}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid size={4}>
            {markup ? (
              <MarkupEditor
                setMarkup={setMarkup}
                selectedFile={selectedFile}
                setFiles={setFiles}
                files={files}
                data={data}
                loadInitialData={loadInitialData}
              />
            ) : (
              <CommentSection data={data} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default JobUploadModal;
