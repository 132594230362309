import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../api/axios";
import { USER_CLIENT_PROJECT_ALLOCATION_SCHEMA } from "../../../utils/schema";
import { FormInputDropdown } from "../../form-components/FormInputDropdown";
import GenerateDataTable from "../../reusable/GenerateDataTable";
import SearchBar from "../../reusable/SearchBar";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { styled } from "@mui/material/styles";
import ThreeDotMenu from "../../reusable/ThreeDotMenu";
import { useAuth } from "../../../context/AuthContext";

function Jobs() {
  const { id } = useParams();
  const { userData } = useAuth();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [jobColumns, setJobColumns] = useState([]);
  const [jobFields, setJobFields] = useState([]);
  const [dropDownData, setDropDownData] = useState({});
  const [btnLoader, setBtnLoader] = useState({ type: null, loading: false });

  const loadDropDownData = async () => {
    try {
      setLoading(true);

      const response = await axiosInstance.get("/getaddJobsDetailsId/" + id);

      if (!response?.data?.error) {
        const formattedProjectsData = response?.data?.data.map(
          (item, index) => ({
            index,
            ...item,
          })
        );
        setRows(formattedProjectsData);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDropDownData();
  }, []);

  const threeDotOptions = [
    {
      name: "View",
      icon: null,
      handler: (row) => {
        navigate(
          `/jobs/user-hop-review/${row?.project_id}/${row?.job_id}/${userData?.ueser_create_id}`
        );
      },
    },
  ];
  const columns = [
    {
      field: "index",
      headerName: "S.No",
      valueGetter: (row) => row + 1,
    },
    {
      field: "unique_job_identifier",
      headerName: "Unique Job Id",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "project_name",
      headerName: "Poject Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "account_name",
      headerName: "Account Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "clients_name",
      headerName: "Client Name",
      flex: 1,
      minWidth: 150,
    },
    ...jobFields,
    {
      field: "view_type",
      headerName: "More",
      flex: 0.5,
      renderCell: ({ row }) => (
        <ThreeDotMenu options={threeDotOptions} row={row} />
      ),
    },
  ];

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ overflowX: "auto", maxWidth: "100%" }}
      >
        <Grid size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              // justifyContent: "center",
              gap: "16px",
              marginBottom: 2,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontSize: "26px",
                lineHeight: "30px",
                marginRight: "16px",
                color: "#333333",
              }}
            >
              Jobs
            </Typography>

            <Box sx={{ flexGrow: 1, maxWidth: "1000px" }}>
              <SearchBar
                setLoading={setLoading}
                setRows={setRows}
                loadInitialData={() => {}}
                searchAPI={"searchContractors"}
              />
            </Box>
          </Box>

          <GenerateDataTable
            rows={rows || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.sno}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Jobs;
