import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
  alpha,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  styled,
  Typography,
} from "@mui/material";
import { ContentCopyOutlined } from "@mui/icons-material";

export const FormInputText = ({
  name,
  control,
  label,
  type,
  placeholder,
  defaultValue,
  maxLength,
  className,
  disabled,
  isViewMode = false,
}) => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    width: "100%",
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FFFFFF",
      border: "1px solid",
      borderColor: "#E0E3E7",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      ...theme.applyStyles("dark", {
        backgroundColor: "#1A2027",
        borderColor: "#2D3843",
      }),
    },
  }));

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const maskedPassword = defaultValue?.replace(/./g, "•");

        const copyToClipboard = () => {
          const textToCopy = defaultValue; // Copy the actual password, not the masked one
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              alert("Copied to clipboard!");
            })
            .catch((err) => {
              console.error("Failed to copy: ", err);
            });
        };
        return (
          <FormControl variant="standard" className="custom-form-control">
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              className="text-input-label"
            >
              {label}
            </InputLabel>
            {isViewMode ? (
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontFamily: "var(--font-primary)",
                  fontWeight: "400",
                  fontSize: "16px",
                  // lineHeight: "22.4",
                  color: "#767676",
                  textWrap: "nowrap",
                  marginTop: "20px",
                }}
              >
                {type == "password" ? maskedPassword : defaultValue}
                {type == "password" && (
                  <IconButton
                    aria-label="copy"
                    onClick={copyToClipboard}
                    sx={{ marginLeft: "8px", width: "auto" }}
                  >
                    <ContentCopyOutlined fontSize="small" />
                  </IconButton>
                )}
              </Typography>
            ) : (
              <>
                <BootstrapInput
                  defaultValue={defaultValue || value}
                  id="bootstrap-input"
                  fullWidth
                  // size="small"
                  type={type || "text"}
                  placeholder={placeholder}
                  className={className}
                  variant="outlined"
                  onChange={onChange}
                  maxLength={maxLength}
                  error={error?.message ? true : false}
                  // margin="normal"
                  disabled={disabled || false}
                />
                <FormHelperText style={{ color: "red" }}>
                  {error?.message || ""}
                </FormHelperText>
              </>
            )}
          </FormControl>
        );
      }}
    />
  );
};
