import {
  Box,
  Grid2 as Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";

function StatStatus({ categoryType, setCategoryType, data }) {
  return (
    <Box sx={{ mb: 3 }}>
      <Box
        sx={{
          bgcolor: "#F6F6F6",
          p: 2,
          px: 3,
          borderRadius: "5px",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={5} sx={{ gap: 3 }}>
          <Grid item size={10} spacing={3} sx={{ flexGrow: 1, gap: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                justifyContent: "center",
              }}
            >
              <Grid item size={4}>
                <Select
                  value={categoryType}
                  onChange={(e) => setCategoryType(e?.target?.value)}
                  displayEmpty
                  style={{
                    width: "100%",
                    height: "56px",
                    padding: "8px",
                    gap: "12px",
                    borderRadius: "5px",
                    opacity: 1,
                    fontSize: 16,
                    fontWeight: 400,
                    border: "1px solid #EF7540",
                  }}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <MenuItem value={""}>ALL</MenuItem>
                  {data?.map((item) => (
                    <MenuItem value={item?.categories_id}>
                      {item?.categories_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                size={4}
                sx={{
                  height: "56px",
                  padding: "8px",
                  gap: "12px",
                  borderRadius: "5px",
                  border: "1px solid #47A0DE",
                  opacity: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    gap: "0px",
                    opacity: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "22.4px",
                    color: "#000000",
                    width: "100%",
                  }}
                >
                  <span>Open</span>
                  <span>62</span>
                </Box>
              </Grid>

              <Grid
                item
                size={4}
                sx={{
                  height: "56px",
                  padding: "8px",
                  gap: "12px",
                  borderRadius: "5px",
                  border: "1px solid #47A0DE",
                  opacity: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    height: "22px",
                    gap: "0px",
                    opacity: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "22.4px",
                    textAlign: "left",
                    color: "#000000",
                    width: "100%",
                  }}
                >
                  <span>Closed</span>
                  <span>0</span>
                </Box>
              </Grid>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Grid
                container
                spacing={3}
                justifyContent={"space-evenly"}
                className="status-count-stats"
              >
                <Grid item xs={2}>
                  <Typography textAlign="center">Assigned</Typography>
                  <Typography textAlign="center">3</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">Submitted</Typography>
                  <Typography textAlign="center">0</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">Re-assigned</Typography>
                  <Typography textAlign="center">0</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">Re-submitted</Typography>
                  <Typography textAlign="center">0</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">N/A Approved</Typography>
                  <Typography textAlign="center">0</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography textAlign="center">Approved</Typography>
                  <Typography textAlign="center">0</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default StatStatus;
// import {
//   Box,
//   Grid2 as Grid,
//   MenuItem,
//   Select,
//   Typography,
// } from "@mui/material";
// import React, { useEffect, useMemo, useState } from "react";
// import { useParams } from "react-router-dom";
// import axiosInstance from "../../../../api/axios";

// function StatStatus({ categoryType, setCategoryType, data}) {
//   const { projectName, id } = useParams();
//   const [jobsdata, setJobsData] = useState([]);

//   useEffect(() => {
//     const fetchJobsData = async () => {
//       try {
//         const response = await axiosInstance.get(`/getJobId/${projectName}/${id}`);
//         const result = response.data;
  
//         console.log("API Response:", result);
  
//         if (result.success && result.data.length > 0) {
//           console.log("Jobs Data from API:", result.data[0]);
//           const jobs = result.data[0].jobsdata || [];
//           console.log("Extracted Jobs Data:", jobs);
//           setJobsData(jobs);
//         } else {
//           console.warn("No jobs data available");
//           setJobsData([]);
//         }
//       } catch (error) {
//         console.error('Error fetching jobs data:', error);
//       }
//     };
  
//     fetchJobsData();
//   }, []);
  

//   const statusCounts = useMemo(() => {
//     console.log("Jobs Data:", jobsdata);
//     let openCount = 0;
//     let closedCount = 0;
//     let assignedCount = 0;
//     let submittedCount = 0;
//     let reassignedCount = 0;
//     let resubmittedCount = 0;
//     let naApprovedCount = 0;
//     let approvedCount = 0;

//     jobsdata?.forEach((job) => {
  
//       const isClosed = job.assigned_at || job.re_assigned_at;

//       if (isClosed) {
//         closedCount++;
//       } else {
//         openCount++;
//       }
//       if (job.assigned_at) assignedCount++;
//       if (job.submitted_at) submittedCount++;
//       if (job.re_assigned_at) reassignedCount++;
//       if (job.re_submitted_at) resubmittedCount++;
//       if (job.na_approved_at) naApprovedCount++;
//       if (job.approved_at) approvedCount++;
//     });

//     console.log("Status Counts:", {
//       openCount,
//       closedCount,
//       assignedCount,
//       submittedCount,
//       reassignedCount,
//       resubmittedCount,
//       naApprovedCount,
//       approvedCount,
//     });

//     return {
//       openCount,
//       closedCount,
//       assignedCount,
//       submittedCount,
//       reassignedCount,
//       resubmittedCount,
//       naApprovedCount,
//       approvedCount,
//     };
//   }, [jobsdata]);

//   return (
//     <Box sx={{ mb: 3 }}>
//       <Box
//         sx={{
//           bgcolor: "#F6F6F6",
//           p: 2,
//           px: 3,
//           borderRadius: "5px",
//           flexGrow: 1,
//         }}
//       >
//         <Grid container spacing={5} sx={{ gap: 3 }}>
//           <Grid item size={10} spacing={3} sx={{ flexGrow: 1, gap: 3 }}>
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 gap: "12px",
//                 justifyContent: "center",
//               }}
//             >
//               <Grid item size={4}>
//                 <Select
//                   value={categoryType}
//                   onChange={(e) => setCategoryType(e?.target?.value)}
//                   displayEmpty
//                   style={{
//                     width: "100%",
//                     height: "56px",
//                     padding: "8px",
//                     gap: "12px",
//                     borderRadius: "5px",
//                     opacity: 1,
//                     fontSize: 16,
//                     fontWeight: 400,
//                     border: "1px solid #EF7540",
//                   }}
//                   sx={{
//                     ".MuiOutlinedInput-notchedOutline": {
//                       border: "none",
//                     },
//                   }}
//                 >
//                   <MenuItem value={""}>ALL</MenuItem>
//                   {data?.map((item) => (
//                     <MenuItem key={item?.categories_id} value={item?.categories_id}>
//                       {item?.categories_name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </Grid>

//               <Grid item size={4}>
//                 <Box
//                   sx={{
//                     height: "56px",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     border: "1px solid #47A0DE",
//                     borderRadius: "5px",
//                     padding: "8px",
//                   }}
//                 >
//                   <span>Open</span>
//                   <span>{statusCounts.openCount}</span>
//                 </Box>
//               </Grid>

//               <Grid item size={4}>
//                 <Box
//                   sx={{
//                     height: "56px",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     border: "1px solid #47A0DE",
//                     borderRadius: "5px",
//                     padding: "8px",
//                   }}
//                 >
//                   <span>Closed</span>
//                   <span>{statusCounts.closedCount}</span>
//                 </Box>
//               </Grid>
//             </Box>

//             <Box sx={{ mt: 2 }}>
//               <Grid container spacing={3} justifyContent={"space-evenly"}>
//                 <Grid item xs={2}>
//                   <Typography textAlign="center">Assigned</Typography>
//                   <Typography textAlign="center">{statusCounts.assignedCount}</Typography>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <Typography textAlign="center">Submitted</Typography>
//                   <Typography textAlign="center">{statusCounts.submittedCount}</Typography>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <Typography textAlign="center">Re-assigned</Typography>
//                   <Typography textAlign="center">{statusCounts.reassignedCount}</Typography>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <Typography textAlign="center">Re-submitted</Typography>
//                   <Typography textAlign="center">{statusCounts.resubmittedCount}</Typography>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <Typography textAlign="center">N/A Approved</Typography>
//                   <Typography textAlign="center">{statusCounts.naApprovedCount}</Typography>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <Typography textAlign="center">Approved</Typography>
//                   <Typography textAlign="center">{statusCounts.approvedCount}</Typography>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Grid>
//         </Grid>
//       </Box>
//     </Box>
//   );
// }

// export default StatStatus;
