import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ArrowLeft, ChatText, Files, Repeat } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../api/axios";
import StatStatus from "./StatStatus";
import GenerateDataTable from "../../../reusable/GenerateDataTable";
import SubmitterJobUploadModal from "./SubmitterJobUploadModal";
import ReviewerJobUploadModal from "./ReviewerJobUploadModal";
import { useAuth } from "../../../../context/AuthContext";
import JobUploadModal from "./JobUploadModal";

function UserHopReview() {
  const { project_id, job_id, user_create_id } = useParams();
  const { userData } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [statusLoader, setStatusLoader] = useState({ id: null, status: false });
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [categoryType, setCategoryType] = useState("");
  const [dropDownData, setDropDownData] = useState({});
  const [jobDetails, setJobDetails] = useState([]);
  const statusCount = { open: 0, closed: 0 };

  const loadInitialData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/getJobHopDetailsId/${project_id}/${job_id}/${user_create_id}`
      );
      if (response?.data?.data?.length > 0) {
        const formattedProjectsData = response?.data?.data?.map(
          (item, index) => ({
            index,
            ...item,
            template_checklist_list:
              item?.template_checklist_list?.map((subItem, subIndex) => ({
                index: subIndex,
                ...subItem,
              })) || [],
          })
        );

        setJobDetails(formattedProjectsData);
        if (selectedRow != null) {
          const selectedRowData = formattedProjectsData?.find(
            (item) => item?.hop_review_id == selectedRow?.hop_review_id
          );
          setSelectedRow(selectedRowData);
        }
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  const loadDropDownData = async () => {
    try {
      setLoading(true);

      const [accountResponse, statusResponse] = await Promise.all([
        axiosInstance.get("/getActiveAccounts"),
        axiosInstance.get("/getActiveStatus"),
      ]);

      if (accountResponse?.data?.data?.length > 0) {
        const formattedProjectsData = accountResponse?.data?.data.map(
          (item, index) => ({
            index,
            ...item,
          })
        );
        setDropDownData((prev) => ({
          ...prev,
          activeAccountList: formattedProjectsData,
        }));
      }

      if (statusResponse?.data?.data?.length > 0) {
        setDropDownData((prev) => ({
          ...prev,
          activeStatusList: statusResponse?.data?.data,
        }));
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadInitialData();
    loadDropDownData();
  }, []);

  const statusDropDownHandler = async (e, row) => {
    const newStatus = e?.target?.value;
    try {
      if (row?.template_check_list_id) {
        setStatusLoader({ id: row?.template_check_list_id, status: true });
        const payload = {
          templates_id: row?.templates_id,
          status_id: newStatus,
          template_check_list_id: row?.template_check_list_id,
        };
        const response = await axiosInstance.post("/updateHopReview", payload);
        if (!response.data.error) {
          loadInitialData();
        }
      }
    } catch (error) {
      console.error("Invalid ID:", error);
    } finally {
      setStatusLoader({ id: null, status: false });
    }
  };
  const columns = [
    {
      field: "index",
      headerName: "S.No",
      valueGetter: (row) => row + 1,
      maxWidth: 75,
    },
    {
      field: "description",
      headerName: "Task Name",
      flex: 1,
      minWidth: 200,
      maxWidth: 350,
    },
    {
      field: "short_description",
      maxWidth: 50,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Tooltip title={row?.short_description}>
          <ChatText size={15} />
        </Tooltip>
      ),
      renderHeader: () => <ChatText size={18} weight="bold" />,
    },
    {
      field: "submitter",
      headerName: "Submitter",
      renderCell: ({ row }) => {
        const submitter = row?.submiter_name_list?.[0]?.first_name || "NA";
        return submitter;
      },
    },
    {
      field: "reviewer",
      headerName: "Reviewer",
      renderCell: ({ row }) => {
        const reviewer = row?.reviewer_name_list?.[0]?.first_name || "NA";
        return reviewer;
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      renderCell: ({ row }) => row?.due_date || "-",
    },
    {
      field: "days",
      headerName: "Days",
      maxWidth: 100,
      renderCell: ({ row }) => row?.days || "0",
    },
    {
      field: "view_type",
      headerName: "Desc",
      sortable: false,
      maxWidth: 50,
      renderHeader: () => <Files size={18} weight="bold" />,
      renderCell: ({ row }) => {
        return (
          <IconButton
            aria-label="arrow"
            onClick={() => {
              setOpen((prev) => true);
              setSelectedRow(row);
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontWeight: 500,
                color: "#636363",
                fontFamily: "var(--font-alternative)",
              }}
            >
              {row?.submiterimage?.length || "0"}
            </span>
          </IconButton>
        );
      },
    },
    {
      field: "iteration",
      headerName: "Desc",
      sortable: false,
      maxWidth: 50,
      renderCell: ({ row }) => row?.submiterimage?.length || "0",
      renderHeader: () => <Repeat size={18} weight="bold" />,
    },
    {
      field: "status_id",
      headerName: "Status",
      flex: 1,
      maxWidth: 175,
      renderCell: ({ row }) =>
        row?.template_check_list_id == statusLoader?.id && statusLoader ? (
          <CircularProgress color="#EF7540" size="16px" />
        ) : (
          <Select
            value={+row?.status_id}
            onChange={(e) => statusDropDownHandler(e, row)}
            displayEmpty
            style={{
              width: "100%",
              height: "40px",
              borderRadius: "5px",
              opacity: 1,
              fontSize: 16,
              fontWeight: 400,
              border: "1px solid #EF7540",
            }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            {dropDownData?.activeStatusList?.map((item) => (
              <MenuItem value={+item?.status_id}>{item?.status_name}</MenuItem>
            ))}
          </Select>
        ),
    },
  ];

  dropDownData?.activeStatusList?.forEach((status) => {
    statusCount[status.status_name] = 0;
  });
  const statusMap = {};
  dropDownData?.activeStatusList?.forEach((status) => {
    statusMap[status.status_id] = status.status_name;
  });

  jobDetails.forEach((row) => {
    const statusName = statusMap[row.status_id];
    if (statusName) {
      statusCount[statusName]++;
    }
    if (row.approved_at && row.na_approved_at) {
      statusCount["closed"]++;
    } else {
      statusCount["open"]++;
    }
  });

  const getUniqueCategories = (checklistList) => {
    const seen = new Set();
    return checklistList
      ?.flatMap((item) => item.categorieslist) // Flatten the list to get all categories
      ?.filter((category) => {
        const duplicate = seen.has(category.category_id);
        seen.add(category.category_id); // Mark this category_id as seen
        return !duplicate; // Keep the category if it's not a duplicate
      });
  };
  const categoryList = getUniqueCategories(
    jobDetails?.[0]?.template_checklist_list
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              marginBottom: 2,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton aria-label="arrow" onClick={() => navigate(-1)}>
                <ArrowLeft size={24} weight="bold" />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  fontSize: "26px",
                  lineHeight: "30px",
                  marginRight: "16px",
                  color: "#333333",
                }}
              >
                {jobDetails?.unique_job_identifier}
              </Typography>
            </Box>
          </Box>
          <StatStatus
            statusCount={statusCount}
            categoryType={categoryType}
            setCategoryType={setCategoryType}
            data={categoryList}
          />
          <GenerateDataTable
            rows={jobDetails || []}
            columns={columns}
            loading={loading}
            getRowId={(row) => row?.template_check_list_id}
          />
        </Grid>
      </Grid>
      {/* {userData?.role_id == 6 && (
        <SubmitterJobUploadModal
          loading={loading}
          setLoading={setLoading}
          loadInitialData={loadInitialData}
          open={open}
          setOpen={setOpen}
          data={selectedRow}
          dropDownData={{
            submitterList: dropDownData?.submitterList,
            reviewerList: dropDownData?.reviewerList,
          }}
          otherPayloadData={jobDetails?.[0]}
        />
      )}
      {userData?.role_id == 5 && (
        <ReviewerJobUploadModal
          loading={loading}
          setLoading={setLoading}
          loadInitialData={loadInitialData}
          open={open}
          setOpen={setOpen}
          data={selectedRow}
          dropDownData={{
            submitterList: dropDownData?.submitterList,
            reviewerList: dropDownData?.reviewerList,
          }}
          otherPayloadData={jobDetails?.[0]}
        />
      )} */}
      <JobUploadModal
        loading={loading}
        setLoading={setLoading}
        loadInitialData={loadInitialData}
        open={open}
        setOpen={setOpen}
        data={selectedRow}
        dropDownData={{
          submitterList: dropDownData?.submitterList,
          reviewerList: dropDownData?.reviewerList,
        }}
        jobDetailsData={jobDetails?.[0]}
      />
    </>
  );
}

export default UserHopReview;
